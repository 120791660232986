import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography, Chip } from "@material-ui/core";
import { alertWarningError, notificacionEliminar } from "../../components/Notificaciones";
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';


import { pink, lightGreen } from '@material-ui/core/colors';
import swal from 'sweetalert';


const valueList = [
  {
    "id": 36,
    "nombre": "Lary Monserrat",
    "apellido": "Gomez Baez",
    "fotoPrinc": "8c0fabbd-d696-4ddd-ac5f-ab5a055309aa.jpeg",
    "urlFoto": "http://api.dev.koki.com.py/api/colaboradores/36/file/8c0fabbd-d696-4ddd-ac5f-ab5a055309aa.jpeg",
    "correo": "lgomez@wom.com",
    "telefono": "0984553322",
    "tipoUsuario": "Puntero"
  },
  {
    "id": 37,
    "nombre": "Maria Leticia",
    "apellido": "Perez Gamarra",
    "fotoPrinc": "c62e5c5f-6aae-4eb1-b162-99523b14350b.jpeg",
    "urlFoto": "http://api.dev.koki.com.py/api/colaboradores/37/file/c62e5c5f-6aae-4eb1-b162-99523b14350b.jpeg",
    "correo": "mperz@wom.com",
    "telefono": "0992334499",
    "tipoUsuario": "Administrador"
  },
  {
    "id": 34,
    "nombre": "Tania Elizabeth",
    "apellido": "Mariz de la Fuente",
    "fotoPrinc": "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHBlcnNvbmFzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    "urlFoto": "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHBlcnNvbmFzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    "correo": "tmariz@wom.com",
    "telefono": "0971234587 ",
    "tipoUsuario": "Puntero"
  }
]
export default function ListaColaboradores() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ content: valueList });



  useEffect(() => {
    //getColaboradores()
  }, []);

  const getColaboradores = async (props) => {
    setIsLoading(true);
    let url = "colaboradores/colaborador-listar/"
    try {
      const response = await axios.post(url, {
        idColaborador: null
      });
      let status = response.status;
      if (status === 200) {
        const colaboradores = response.data;
        setData({ ...data, content: colaboradores?.result });

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const title = (<Button
    size='small'
    variant="outlined"
    color="primary"
    startIcon={<AddIcon />}
    onClick={() => history.push("../nuevo-colaborador")}

  >
    Agregar
  </Button>);

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true
    },
    {
      title: "Imagen",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFoto}`}
        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "apellido",
      // width: "10%",

    },

    {
      title: "Correo",
      field: "correo",
      width: "20%",

    },

    {
      title: "Teléfono",
      field: "telefono",
      width: "12%",

    },


  ];
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];


  const handleInactivar = (event, props) => {
    event.stopPropagation();
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar a ${props.data?.nombre} ${props.data?.apellido}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarColaborador(props.data)
      }
    });
  };


  const eliminarColaborador = async (props) => {

    swal("¡OPERACIÓN EXITOSA!", {
      icon: "success",
      buttons: false,
      timer: 1500,
    });
/*
    setIsLoading(true);
    let url = "colaboradores/colaborador-inactivar"
    try {
      const response = await axios.post(url,
        {
          idColaborador: props?.id
        });
      let status = response.status;
      if (status === 200) {
        getColaboradores()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
    */
  };

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle"
                sx={{ bgcolor: "red" }} >
                <HighlightOffIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Inactivar"
            variant="outlined"
            color="secondary"
          />
        </Box>

      </>
    );
  };

  return (

    <>
      {userContext.state.nombreUsu !== "" ?
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              childrenAccions,
            }}
          />



        </>
        : <AccesoDenegado />}
    </>
  );
}
