import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Alert from '@material-ui/lab/Alert';

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from '@material-ui/core/CardActionArea';

import LinearProgress from "@material-ui/core/LinearProgress";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { useStyles } from "../assets/styles/CustomStyles";
import TypographyBold from "../components/TypographyBold";
import { MakeTables } from "../components/MaterialTables/MakeTables";
import AddIcon from "@material-ui/icons/AddCircle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PinDropIcon from "@material-ui/icons/PinDrop";
import FaceIcon from "@material-ui/icons/Face";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CachedIcon from "@material-ui/icons/Cached";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import TodayIcon from "@material-ui/icons/Today";
import HistoryIcon from "@material-ui/icons/History";
import UserContext from "../utils/user/UserContext";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ContactsIcon from '@material-ui/icons/Contacts';
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import {
  red,
  pink,
  indigo,
  deepPurple,
  orange,
  lightGreen,
} from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import axios from "../utils/axios";
import IconButton from "@material-ui/core/IconButton";

import { alertWarningError } from "../components/Notificaciones";
import BackdropCustom from "../components/BackdropCustom";

import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CheckIcon from "@material-ui/icons/Check";
import StorefrontIcon from '@material-ui/icons/Storefront';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
const valueDefaultFiltro = {
  fechaDesde: "",
  fechaHasta: ""
}
export default function Tablero() {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWommers, setIsLoadingWommers] = useState(false);
  const [isLoadingServicios, setIsLoadingServicios] = useState(false);
  const [listaWommers, setListaWommers] = useState({ content: [] });
  const [listaServios, setListaServios] = useState({ content: [] });
  const [data, setData] = useState({
    servicios: "",
    usuarios: "",
    wommers: "",
    empresas: "",
    serviciosRealizados: "",
  });


  useEffect(() => {
    getData();
    getWomers()
    getServicios()
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const url = "dashboard/panel";
    try {
      const response = await axios.post(url);

      setIsLoading(false);
      if (response.status === 200) {
        let dataResponse = response?.data?.result[0];
        setData({
          ...data,
          servicios: dataResponse?.servicios,
          wommers: dataResponse?.wommer,
          usuarios: dataResponse?.usuarios,
          empresas: dataResponse?.empresas,
          serviciosRealizados: dataResponse?.serviciosRealizados,
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };
  const getServicios = async (props) => {
    setIsLoadingServicios(true);
    let url = "dashboard/rank-servicio"
    try {
      const response = await axios.post(url);
      let status = response.status;
      if (status === 200) {
        const proyectos = response.data;
        setListaServios({
          ...listaServios,
          content: proyectos?.result,
        });
        console.log(proyectos?.result)
        setIsLoadingServicios(false);
      }
    } catch (error) {
      setIsLoadingServicios(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getWomers = async (props) => {
    setIsLoadingWommers(true);
    let url = "dashboard/rank-wommer"
    try {
      const response = await axios.post(url);
      let status = response.status;
      if (status === 200) {
        const proyectos = response.data;
        setListaWommers({
          ...listaWommers,
          content: proyectos?.result,
        });
        console.log(proyectos?.result)
        setIsLoadingWommers(false);
      }
    } catch (error) {
      setIsLoadingWommers(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const titleWomers = (
    <>
      <List >
        <ListItem >
          <ListItemText primary="TOP 5 DE WOMERS MAS SOLICITADO" secondary="Womers con mayor solicitudes en el mes " />
        </ListItem>
      </List>

    </>

  );

  const titleServicios = (
    <>
      <List >
        <ListItem >

          <ListItemText primary="TOP 5 DE SERVICIOS MAS ADQUIRIDOS" secondary="Servicios con mayor solicitedes en el mes " />
        </ListItem>
      </List>
    </>

  );

  const columnsWommers = [
    {
      title: "ID",
      field: "id_cliente",
      width: "1%",
      hidden: true,
    },

    {
      title: "",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 30, borderRadius: "20%" }}
          src={`${rowData?.urlFoto}`}
        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nombre",
      render: (rowData) => (
        <Typography
          variant="button"
          display="block"
          color="initial"
        //  style={{ color: "#ffffff" }}
        >
          {rowData?.nombre}
        </Typography>
      )
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "apellido",
      render: (rowData) => (
        <Typography
          variant="button"
          display="block"
          color="initial"
        //  style={{ color: "#ffffff" }}
        >
          {rowData?.apellido}
        </Typography>
      )
      //  width: "15%",
    },
    {
      title: "Rubro",
      field: "rubro",
      width: "15%",

    },
    {
      title: "Puntos",
      field: "puntos",
      width: "8%",
      align: "center",
      render: (rowData) => (
        <Alert icon={<LoyaltyIcon fontSize="small" />} variant="outlined" severity="info" style={{ padding: "0px 10px" }}>
          {parseFloat(rowData?.puntos).toLocaleString('es')}
        </Alert>

      ),
    },
    {
      title: "Valoración",
      field: "valoracion",
      width: "5%",
      align: "center",
      render: (rowData) => (
        <Alert icon={<StarBorderIcon fontSize="small" />} variant="outlined" severity="info" style={{ padding: "0px 10px" }}>
          {parseFloat(rowData?.valoracion).toLocaleString('es')}
        </Alert>

      ),
    },
    {
      title: "Servicios",
      field: "servicios",
      width: "8%",
      align: "center",
      render: (rowData) => (
        <Alert icon={null} variant="outlined" severity="warning" style={{ padding: "0px 10px" }}>
          {rowData?.servicios}
        </Alert>

      ),
    },
    {
      title: "Realizados",
      field: "realizados",
      width: "8%",
      align: "center",
      render: (rowData) => (
        <Alert icon={null} variant="outlined" severity="success" style={{ padding: "0px 10px" }}>
          {rowData?.realizados}
        </Alert>

      ),
    },

    {
      title: "TOTAL",
      field: "total",
      width: "10%",
      align: "center",
      render: (rowData) => (
        <Alert icon={<AccountBalanceIcon fontSize="small" />} variant="outlined" severity="warning" style={{ padding: "0px 10px" }}>
          {parseFloat(rowData?.total).toLocaleString('es')}
        </Alert>

      ),
    },


  ];

  const columnsServicios = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "Nro.",
      field: "nro",
      width: "1%",
    },

    {
      title: "",
      field: "urlFoto",
      align: "center",

      width: "12%",
      render: (rowData) => {
        if (rowData?.urlFoto === null) {
          return (
            < NotInterestedIcon fontSize="large" color="error" />
          )
        } else {
          let imag = rowData?.urlFoto.split(",")
          return (
            < img
              style={{ height: 55, borderRadius: "10%" }}
              src={`${imag ? imag[0] : rowData?.urlFoto}`}
            />
          )
        }
      }
    },
    {
      title: "Nombre del servicio",
      field: "nombre",
      render: (rowData) => (
        <Typography
          variant="button"
          display="block"
          color="initial"
        //  style={{ color: "#ffffff" }}
        >
          {rowData?.nombre}
        </Typography>
      )
      //  width: "15%",
    },
    {
      title: "Rubro",
      field: "rubro",
      width: "15%",

    },
    {
      title: "Valoración",
      field: "valoracion",
      width: "8%",
      align: "center",
      render: (rowData) => (
        <Alert icon={<StarBorderIcon fontSize="small" />} variant="outlined" severity="info" style={{ padding: "0px 10px" }}>
          {parseFloat(rowData?.valoracion).toLocaleString('es')}
        </Alert>

      ),
    },
    {
      title: "Realizados",
      field: "realizados",
      width: "10%",
      align: "center",
      render: (rowData) => (
        <Alert icon={null} variant="outlined" severity="success" style={{ padding: "0px 10px" }}>
          {rowData?.realizados}
        </Alert>

      ),
    },
    {
      title: "Utilidad",
      field: "ganancia",
      width: "15%",
      align: "center",
      render: (rowData) => (
        <Alert icon={<AccountBalanceIcon fontSize="small" />} variant="outlined" severity="warning" style={{ padding: "0px 10px" }}>
          {parseFloat(rowData?.ganancia).toLocaleString('es')}
        </Alert>

      ),
    },


  ];

  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    search: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "75vh",
    paging: false,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <List >
            <ListItem >
              <ListItemAvatar >
                <Avatar style={{ backgroundColor: "#07a896" }}>
                  <DashboardIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Dashboard" secondary="Datos generales de WOMERS" />
            </ListItem>
          </List>
        </Grid>


      </Grid>

      <Grid container spacing={6} justifyContent="center" alignItems="center" >
        {/** servicios mas adquiridos */}
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <MakeTables
            isLoading={isLoadingServicios}
            title={titleServicios}
            columns={columnsServicios}
            data={listaServios.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              // eliminar,
              //finalizar,
              //detalle,
              // childrenAccions,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={{ boxShadow: "0px 5px 1px -1px rgb(213 0 74 / 100%), 0px 1px 1px 0px rgb(213 0 74 / 14%), 0px 1px 8px 0px rgb(213 0 74 / 14%)" }} >
                <CardActionArea
                //onClick={() => history.push("./proyectos/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <WorkIcon
                          style={{ color: "rgb(213 0 74 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                      //  style={{ color: "#ffffff" }}
                      >
                        SERVICIOS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(213 0 74 / 100%)" }}
                      >
                        {data.servicios}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={{ boxShadow: "0px 5px 1px -1px rgb(2 81 18 / 100%), 0px 1px 1px 0px rgb(2 81 18 / 14%), 0px 1px 8px 0px rgb(2 81 18 / 14%)" }} >
                <CardActionArea
                //  onClick={() => history.push("./gestiones/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <PersonIcon
                          style={{ color: "rgb(2 81 18 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                      //  style={{ color: "#ffffff" }}
                      >
                        USUARIOS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(2 81 18 / 100%)" }}
                      >
                        {data.usuarios}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={{ boxShadow: "0px 5px 1px -1px rgb(7 168 150 / 100%), 0px 1px 1px 0px rgb(7 168 150 / 14%), 0px 1px 8px 0px rgb(7 168 150 / 14%)" }} >
                <CardActionArea
                // onClick={() => history.push("./tareas/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <AssignmentIndIcon
                          style={{ color: "rgb(7 168 150 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                      //  style={{ color: "#ffffff" }}
                      >
                        WOMMERS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(7 168 150 / 100%)" }}
                      >
                        {data.wommers}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={{ boxShadow: "0px 5px 1px -1px rgb(219 83 34 / 100%), 0px 1px 1px 0px rgb(219 83 34 / 14%), 0px 1px 8px 0px rgb(219 83 34 / 14%)" }} >
                <CardActionArea
                // onClick={() => history.push("./tareas/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <HomeWorkIcon
                          style={{ color: "rgb(219 83 34 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                      //  style={{ color: "#ffffff" }}
                      >
                        EMPRESAS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(219 83 34 / 100%)" }}
                      >
                        {data.empresas}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={{ boxShadow: "0px 5px 1px -1px rgb(359 30 4 / 100%), 0px 1px 1px 0px rgb(359 30 / 14%), 0px 1px 8px 0px rgb(359 30 / 14%)" }} >
                <CardActionArea
                // onClick={() => history.push("./tareas/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <BeenhereIcon
                          style={{ color: "rgb(359 30 4/ 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                      //  style={{ color: "#ffffff" }}
                      >
                        SERVICOS REALIZADOS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(359 30 / 100%)" }}
                      >
                        {data.serviciosRealizados}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>



        {/**  wommers mas solicitados */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MakeTables
            isLoading={isLoadingWommers}
            title={titleWomers}
            columns={columnsWommers}
            data={listaWommers.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              // eliminar,
              //finalizar,
              //detalle,
              // childrenAccions,
            }}
          />

        </Grid>

      </Grid>

    </>
  );
}
