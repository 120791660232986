import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from '@material-ui/core/CardActionArea';

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { red, green, blue } from "@material-ui/core/colors";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TypographyBold from "../../components/TypographyBold";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import BackdropCustom from "../../components/BackdropCustom";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import ReportIcon from '@material-ui/icons/Report';
import PhotoIcon from '@material-ui/icons/Photo';
import axios from "../../utils/axios";
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import swal from 'sweetalert';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddIcon from "@material-ui/icons/AddCircle";
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SlidersFotos from "../../components/SlidersFotos";
import DescriptionIcon from '@material-ui/icons/Description';



export default function DetalleServicios() {
  const classes = useStyles();
  const history = useHistory();
  const dataProps = history.location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [detalleServicio, SetDetalleServicio] = useState({});
  const [galeria, setGaleria] = useState([]);


  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idServicio) {
      SetDetalleServicio(dataProps)

      let imag = dataProps?.urlFotos?.split(",")
      let imagen = []
      imagen.push(dataProps?.urlFotos)
      imag ? setGaleria(imag) : galeria.push(imagen)


      //  getDetalle(dataProps?.idServicio)
      console.log(galeria)
      console.log(imagen)
    }

  }, []);
  const getDetalle = async (props) => {
    setIsLoading(true);
    let url = "servicios/listar";
    try {
      const response = await axios.post(url, {
        idServicio: props,

      });
      let status = response.status;
      if (status === 200) {

        const responseData = response.data;

        if (response.data?.ok) {
          //setEspecialidadList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        //  style={{ padding: 2 }}
        >
          <Card >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  <ReportIcon />
                </Avatar>
              }
              action={
                <Button
                  size="small"
                  color="default"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Atras
                </Button>
              }
              title="Detalle del Servicio"
              subheader={
                <Chip label={"Nro. " + detalleServicio?.idServicio} size="small" color="primary" />
              }
            />

            <CardContent style={{ padding: 5 }}>




            </CardContent>

          </Card>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card >
            <CardHeader
              avatar={
                <Avatar aria- label="recipe" className={classes.avatar}>
                  <PhotoIcon />
                </Avatar>
              }
              title={<TypographyBold
                variant="body2"
                display="block"
                color="textSecondary">
                Galería de la publicación
              </TypographyBold>}

            />

            <CardContent>
              <SlidersFotos fotos={galeria} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card >
            <CardHeader
              avatar={
                <Avatar aria- label="recipe" className={classes.avatar}>
                  <DescriptionIcon />
                </Avatar>
              }
              title={<TypographyBold
                variant="body2"
                display="block"
                color="textSecondary">
                Información del servicio
              </TypographyBold>}

            />

            <CardContent>

              <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    Descripción
                  </TypographyBold>
                  <Typography
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    {detalleServicio?.descripcion}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    Ciudad
                  </TypographyBold>
                  <Typography
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    {detalleServicio?.ciudad}
                  </Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    Departamento
                  </TypographyBold>
                  <Typography
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    {detalleServicio?.departamento}
                  </Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    Es precio fijo
                  </TypographyBold>
                  <Typography
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    {detalleServicio?.esPrecioFijo ? "SI" : "NO"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    Fecha Creación
                  </TypographyBold>
                  <Typography
                    variant="body2"
                    display="block"
                    color="textSecondary">
                    {detalleServicio?.fechaCreacion}
                  </Typography>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
          <Card className={classes.root}>
            <CardActionArea onClick={() => history.push("/registros/womers-activos/detalle/", detalleServicio)}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" variant="rounded" alt="Remy Sharp" src={detalleServicio?.urlFotoUsuario} />
                }

                title={detalleServicio?.nombreUsuario + " " + detalleServicio?.apellidoUsuario}
                subheader={detalleServicio?.nombreUsuario ?
                  <Link
                    component="a"
                    target="_blank"
                    href={`https://wa.me/${dataProps?.nombre}?text=Hola ${detalleServicio?.nombreUsuario} ${detalleServicio?.apellidoUsuario}`}
                    color="inherit"
                  > {detalleServicio?.celular}  </Link>
                  : "Sin celular"}

              />

              <CardContent>

              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>

        <Grid item xs={12} sm={6} md={10} lg={10} xl={10}>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  RUBRO
                </Typography>
                <TypographyBold variant="h6" component="h3" color="primary"   >
                  {detalleServicio?.rubro}
                </TypographyBold>
              </Paper >
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  ESPECIALIDAD
                </Typography>
                <TypographyBold variant="h6" component="h3" color="primary"   >
                  {detalleServicio?.especialidad ? detalleServicio?.especialidad : "Sin datos"}
                </TypographyBold>
              </Paper >
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  DURACION
                </Typography>
                <TypographyBold variant="h6" component="h2" color="primary"  >
                  {detalleServicio?.duracion}
                </TypographyBold>
              </Paper >
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  MODALIDAD
                </Typography>
                <TypographyBold variant="h6" component="h2" color="primary"  >
                  {detalleServicio?.modalidad}
                </TypographyBold>
              </Paper >
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  VALORACION
                </Typography>
                <TypographyBold variant="h6" component="h2" color="primary"  >
                  {detalleServicio?.valoracionServicio}
                </TypographyBold>
              </Paper >
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Paper style={{ padding: 20, textAlign: "center" }} >
                <Typography className={classes.title} color="textSecondary" variant="body2">
                  PRECIO
                </Typography>
                <TypographyBold variant="h6" component="h2" color="primary"   >
                  {detalleServicio?.precio}
                </TypographyBold>
              </Paper >
            </Grid>
          </Grid>
        </Grid>

      </Grid>


    </>
  );
}
