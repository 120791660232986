import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { red, green, blue } from "@material-ui/core/colors";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TypographyBold from "../../components/TypographyBold";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import BackdropCustom from "../../components/BackdropCustom";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import axios from "../../utils/axios";
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import swal from 'sweetalert';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddIcon from "@material-ui/icons/AddCircle";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function DetalleRubro() {
  const classes = useStyles();
  const history = useHistory();
  const dataProps = history.location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [especialidadList, setEspecialidadList] = useState([]);
  const [relacionList, setRelacionList] = useState([]);
  const [especialidad, setEspecialidad] = useState({});
  const [open, setOpen] = useState(false);
  const [openRelacion, setOpenRelacion] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [profesionList, setProfesionList] = useState([]);

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idRubro) {
      getDetalle(dataProps?.idRubro)
      getRelacion(dataProps?.idRubro)
      getProfesion()
    }

  }, []);
  const getDetalle = async (props) => {
    setIsLoading(true);
    let url = "especialidades/listar";
    try {
      const response = await axios.post(url, {
        idRubro: props,
        estado: "ACTIVO"
      });
      let status = response.status;
      if (status === 200) {

        const responseData = response.data;

        if (response.data?.ok) {
          setEspecialidadList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };
  const getRelacion = async (props) => {
    setIsLoading(true);
    let url = "snpprelacion/listar";
    try {
      const response = await axios.post(url, {
        idRubro: props,
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;

        if (response.data?.ok) {
          setRelacionList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }



      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getProfesion = async () => {
    setIsLoading(true);
    let url = "snppprofesion/listar";
    try {
      const response = await axios.post(url);
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;

        if (response.data?.ok) {
          setProfesionList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const handleEliminar = (props) => {
    console.log(props);
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres eliminar  ${props?.nombre}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminar(props);
      }
    });
  };

  const eliminar = async (props) => {
    setIsLoading(true);
    let url = "especialidades/inactivar/"
    try {
      const response = await axios.post(url, {
        idEspecialidad: props?.idEspecialidad
      });
      let status = response.status;
      if (status === 200) {

        if (response.data?.ok) {
          getDetalle(props?.idRubro)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          });
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      swal("¡Error. Vuelva a intentar!", {
        icon: "warning",
        buttons: false,
        timer: 1500,
      });
      console.log("error catch: ", error)
    }
  };

  const handleEliminarRelacion = (props) => {
    console.log(props);
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres eliminar  ${props?.snppProfesion}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarRelacion(props);
      }
    });
  };

  const eliminarRelacion = async (props) => {
    setIsLoading(true);
    let url = "snpprelacion/inactivar"
    try {
      const response = await axios.post(url, {
        idRelacion: props?.idRelacion
      });
      let status = response.status;
      if (status === 200) {

        if (response.data?.ok) {
          getRelacion(props?.idRubro)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          });
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      swal("¡Error. Vuelva a intentar!", {
        icon: "warning",
        buttons: false,
        timer: 1500,
      });
      console.log("error catch: ", error)
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenRelacion = () => {
    setOpenRelacion(true);
  };
  const handleCloseRelacion = () => {
    setOpenRelacion(false);
  };

  const handleOpenEditar = (event, props) => {
    event.stopPropagation();
    console.log(props);
    setEspecialidad(props)
    setOpenEditar(true);
  };

  const handleCloseEditar = () => {
    setOpenEditar(false);
  };
  function AgregarEspecialidad(props) {
    const { open, onClose, data } = props;
    const [especialidad, setEspecialidad] = useState("");

    const handleGuardar = async () => {
      onClose();
      let url = "especialidades/crear";

      let newObject = {
        idRubro: data?.idRubro,
        nombre: especialidad,
      };

      try {
        const response = await axios.post(url, newObject);
        let status = response.status;
        if (status === 200) {
          console.log(response.data);

          if (response.data?.ok) {
            swal("¡OPERACIÓN EXITOSA!", {
              icon: "success",
              buttons: false,
              timer: 1500,
            });
            history.go(0);
          } else {
            setIsLoading(false);
            notificacionAlerta(response.data?.message);
          }


        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      }
    };

    const handleChangeNombre = (event) => {
      setEspecialidad(event.target.value);
    };

    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          NUEVA ESPECIALIDAD
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="descripcion"
                value={especialidad}
                onChange={(value) => handleChangeNombre(value)}
                label="Nombre"
                type="text"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose();
                }}
              >
                Salir
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={especialidad === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar();
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  function EditarEspecialidad(props) {
    const { open, onClose, data } = props;
    const [especialidad, setEspecialidad] = useState({
      idEspecialidad: 0,
      nombre: ""
    });

    useEffect(() => {
      console.log(data);
      if (data?.idEspecialidad > 0) {
        let copyInput = {
          ...especialidad,
          idEspecialidad: data?.idEspecialidad,
          nombre: data?.nombre,
        };
        setEspecialidad(copyInput);

      }
    }, []);

    const handleGuardar = async () => {
      onClose();
      let url = "especialidades/actualizar";

      try {
        const response = await axios.post(url, especialidad);
        let status = response.status;
        if (status === 200) {

          if (response.data?.ok) {
            swal("¡OPERACIÓN EXITOSA!", {
              icon: "success",
              buttons: false,
              timer: 1500,
            });
            history.go(0);
          } else {
            setIsLoading(false);
            notificacionAlerta(response.data?.message);
          }


        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      }
    };

    const handleChangeNombre = (event) => {
      let copyInput = { ...especialidad, nombre: event.target.value };
      setEspecialidad(copyInput);
    };

    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          EDITAR ESPECIALIDAD
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="descripcion"
                value={especialidad.nombre}
                onChange={(value) => handleChangeNombre(value)}
                label="Nombre"
                type="text"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose();
                }}
              >
                Salir
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={especialidad.nombre === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar();
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  function AgregarRelacion(props) {
    const { open, onClose, data, profesion } = props;
    console.log(props)
    const [profesionSelect, setPofesionSelect] = useState({});

    const onSelectProfesion = (e, value) => {
      if (value && value?.idSnppProfesion !== profesionSelect?.idSnppProfesion) {
        setPofesionSelect(value)
      }

      if (value === null) {
        setPofesionSelect({})
      }
    };
    const handleGuardar = async () => {
      onClose();
      let url = "snpprelacion/crear";

      let newObject = {
        idRubro: data?.idRubro,
        idProfesion: profesionSelect?.idSnppProfesion,
      };

      try {
        const response = await axios.post(url, newObject);
        let status = response.status;
        if (status === 200) {

          if (response.data?.ok) {
            swal("¡OPERACIÓN EXITOSA!", {
              icon: "success",
              buttons: false,
              timer: 1500,
            });
            history.go(0);
          } else {
            setIsLoading(false);
            notificacionAlerta(response.data?.message);
          }


        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      }
    };

    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          NUEVA RELACION SNPP
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Autocomplete
                size="small"
                value={profesionSelect || ""}
                onChange={onSelectProfesion}
                options={profesion}
                getOptionLabel={(option) =>
                  option.nombre ? option.nombre : ""
                }
                renderOption={(option) => (
                  <React.Fragment>{option?.nombre}</React.Fragment>
                )}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Profesión"
                    name="Profesión"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose();
                }}
              >
                Salir
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={especialidad === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar();
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: 2 }}
        >
          <Card >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  <BusinessCenterIcon />
                </Avatar>
              }
              title={dataProps?.nombre}
              subheader={
                <Chip label={"Nro. " + dataProps?.idRubro} size="small" color="primary" />
              }
            />

            <CardContent style={{ padding: 5 }}>
              <Box p={2}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <TypographyBold
                      variant="body2"
                      display="block"
                      color="textSecondary"
                    >
                      Especialidades

                      <Button
                        size="small"
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpen()}
                      >
                        Agregar
                      </Button>


                    </TypographyBold>




                  </Grid>


                  {especialidadList.length ? especialidadList.map((value) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={value?.nombre}>
                        <Card>
                          <CardHeader
                            avatar={
                              <Avatar
                                style={{ backgroundColor: "#004AAD" }}
                              >
                                <CheckBoxIcon
                                  style={{ color: "#ffffff" }}
                                />
                              </Avatar>
                            }
                            title={
                              <Typography variant="body1" display="block">
                                {value?.nombre}
                              </Typography>
                            }
                            subheader={
                              <Chip
                                label={"Nro. " + value?.idEspecialidad}
                                variant="default"
                                color="secondary"
                                size="small"

                              />
                            }
                          />
                          <CardActions>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignContent="center"
                              spacing={2}
                            >
                              <Grid item>
                                <Chip
                                  onClick={(e) => handleOpenEditar(e, value)}

                                  avatar={
                                    <Avatar variant="circular"
                                      sx={{ bgcolor: "red" }} >
                                      <EditIcon fontSize='small' style={{ color: "#ffffff" }} />
                                    </Avatar>
                                  }
                                  label="Editar"
                                  variant="outlined"
                                  color="secondary"
                                />
                              </Grid>
                              <Grid item>
                                <Chip
                                  onClick={(e) => handleEliminar(value)}
                                  avatar={
                                    <Avatar variant="circular"
                                      sx={{ bgcolor: "red" }} >
                                      <HighlightOffIcon fontSize='small' style={{ color: "#ffffff" }} />
                                    </Avatar>
                                  }
                                  label="Inactivar"
                                  variant="outlined"
                                  color="default"
                                />
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>

                    )
                  })
                    :
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Alert icon={false} variant="outlined" severity="success"
                        style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}>
                        Sin Especialidades
                      </Alert>
                    </Grid>
                  }


                </Grid>
              </Box>


              <Box p={2}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <TypographyBold
                      variant="body2"
                      display="block"
                      color="textSecondary"
                    >
                      Relación profesión SNPP

                      <Button
                        size="small"
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenRelacion()}
                      >
                        Asignar
                      </Button>


                    </TypographyBold>




                  </Grid>


                  {relacionList.length ? relacionList.map((value) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={value?.idRelacion}>
                        <Card>
                          <CardHeader
                            avatar={
                              <Avatar
                                style={{ backgroundColor: "#004AAD" }}
                              >
                                <CheckBoxIcon
                                  style={{ color: "#ffffff" }}
                                />
                              </Avatar>
                            }
                            title={
                              <Typography variant="body1" display="block">
                                {value?.snppProfesion}
                              </Typography>
                            }
                            subheader={
                              <Chip
                                label={"Nro. " + value?.idSnppProfesion}
                                variant="default"
                                color="secondary"
                                size="small"

                              />
                            }
                          />
                          <CardActions>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignContent="center"
                              spacing={2}
                            >

                              <Grid item>
                                <Chip
                                  onClick={(e) => handleEliminarRelacion(value)}
                                  avatar={
                                    <Avatar variant="circular"
                                      sx={{ bgcolor: "red" }} >
                                      <HighlightOffIcon fontSize='small' style={{ color: "#ffffff" }} />
                                    </Avatar>
                                  }
                                  label="Inactivar"
                                  variant="outlined"
                                  color="default"
                                />
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>

                    )
                  })
                    :
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Alert icon={false} variant="outlined" severity="success"
                        style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}>
                        Sin Relación SNPP
                      </Alert>
                    </Grid>
                  }


                </Grid>
              </Box>

            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignContent="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    color="default"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Salir
                  </Button>
                </Grid>

              </Grid>
            </CardActions>
          </Card>
        </Grid>





      </Grid>

      <Box pb={2} pt={6} className={classes.footerBotttom}>
        <Copyright />
      </Box>
      {openRelacion && <AgregarRelacion open={openRelacion} onClose={handleCloseRelacion} data={dataProps} profesion={profesionList} />}
      {open && <AgregarEspecialidad open={open} onClose={handleClose} data={dataProps} />}
      {openEditar && <EditarEspecialidad open={openEditar} onClose={handleCloseEditar} data={especialidad} />}

    </>
  );
}
