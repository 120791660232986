import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from '@material-ui/core/CardActionArea';

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { red, green, blue } from "@material-ui/core/colors";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TypographyBold from "../../components/TypographyBold";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import BackdropCustom from "../../components/BackdropCustom";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import ReportIcon from '@material-ui/icons/Report';
import axios from "../../utils/axios";
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import swal from 'sweetalert';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddIcon from "@material-ui/icons/AddCircle";
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function DetalleReportados() {
  const classes = useStyles();
  const history = useHistory();
  const dataProps = history.location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [especialidadList, setEspecialidadList] = useState([]);


  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idRubro) {
      //      getDetalle(dataProps?.idRubro)

    }

  }, []);
  const getDetalle = async (props) => {
    setIsLoading(true);
    let url = "especialidades/listar";
    try {
      const response = await axios.post(url, {
        idRubro: props,
        estado: "ACTIVO"
      });
      let status = response.status;
      if (status === 200) {

        const responseData = response.data;

        if (response.data?.ok) {
          setEspecialidadList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: 2 }}
        >
          <Card >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  <ReportIcon />
                </Avatar>
              }
              title="Detalle de reportado"
              subheader={
                <Chip label={"Nro. " + dataProps?.idUsuario} size="small" color="primary" />
              }
            />

            <CardContent style={{ padding: 5 }}>
              <Box p={2}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Card className={classes.root}>
                      <CardActionArea onClick={() => history.push("/registros/womers-activos/detalle/", dataProps)}>
                        <CardHeader
                          avatar={
                            <Avatar aria-label="recipe" variant="rounded" alt="Remy Sharp" src={dataProps?.urlFotoPerfil} />
                          }
                          title={dataProps?.nombre + " " + dataProps?.apellido}
                          subheader={dataProps?.nombre ?
                            <Link
                              component="a"
                              target="_blank"
                              href={`https://wa.me/${dataProps?.nombre}?text=Hola ${dataProps?.nombre} ${dataProps?.apellido}`}
                              color="inherit"
                            > {dataProps?.nombre}  </Link>
                            : "Sin celular"}

                        />

                        <CardContent>

                        </CardContent>
                      </CardActionArea>
                    </Card>

                  </Grid>


                  <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
                    <Card className={classes.root}>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={classes.avatar}>
                            <SpeakerNotesOffIcon />
                          </Avatar>
                        }

                        title={<TypographyBold
                          variant="body2"
                          display="block"
                          color="textSecondary">
                          Lista de motivos
                        </TypographyBold>}
                      />

                      <CardContent>
                        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {
                              especialidadList.length ? especialidadList.map(
                                (value) => {
                                  return (

                                    <List component="nav">
                                      <ListItem button>
                                        <ListItemIcon>
                                          <CancelPresentationIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Inbox" />
                                      </ListItem>
                                      <Divider />
                                    </List>
                                  )
                                }
                              ) :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                  <List component="nav">
                                    <ListItem button>
                                      <ListItemIcon>
                                        <CancelPresentationIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Sin motivo" />
                                    </ListItem>
                                    <Divider />
                                  </List>
                                </Grid>

                            }
                          </Grid>
                        </Grid>


                      </CardContent>

                    </Card>

                  </Grid>

                </Grid>
              </Box>



            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignContent="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    color="default"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Salir
                  </Button>
                </Grid>

              </Grid>
            </CardActions>
          </Card>
        </Grid>





      </Grid>

      <Box pb={2} pt={6} className={classes.footerBotttom}>
        <Copyright />
      </Box>

    </>
  );
}
