import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography, Chip } from "@material-ui/core";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import logoW from '../../assets/images/logoW.png'

import { pink, lightGreen } from '@material-ui/core/colors';
import swal from 'sweetalert';

export default function ListaReportados() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ content: [] });



  useEffect(() => {
    getData()
  }, []);

  const getData = async (props) => {
    setIsLoading(true);
    let url = "wommer/listar"
    try {
      const response = await axios.post(url, {
        idUsuario: null,
        estado: "ACTIVO"
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          setData({ ...data, content: responseData?.result });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const title = (<Button
    size='small'
    variant="outlined"
    color="primary"
    startIcon={<AddIcon />}
  //onClick={() => history.push("../nuevo-colaborador")}

  >
    Agregar
  </Button>);

  const columns = [
    {
      title: "ID",
      field: "idUsuario",
      width: "1%",
      hidden: true
    },
    {
      title: "Imagen",
      field: "urlFotoPerfil",
      align: "center",
      render: (rowData) => (rowData?.urlFotoPerfil ?
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFotoPerfil}`}
        />
        : <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${logoW}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Fecha Creación",
      field: "fechaCreacion",
      width: "15%",

    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "apellido",
      // width: "10%",

    },



    {
      title: "Documento",
      field: "nroDocumento",
      width: "12%",

    },
    {
      title: "Ciudad",
      field: "ciudad",
      width: "12%",

    },
    {
      title: "Publicaciones",
      field: "tituloSnpp",
      width: "15%",
      align: "center",
      render: (rowData) => (
        rowData.tituloSnpp === 1 ?
          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px" }}>
            SI
          </Alert> : <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px" }}>
            NO
          </Alert>

      ),

    },

    {
      title: "Reportado",
      field: "emiteFactura",
      width: "10%",
      align: "center",
      render: (rowData) => (
        rowData.emiteFactura === 1 ?
          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px" }}>
            SI
          </Alert> : <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px" }}>
            NO
          </Alert>

      ),

    },
  ];
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];


  const handleDetalle = (event, props) => {
    event.stopPropagation();
    history.push("./detalle/", props.data);

  };
  const handleCambiarEstado = (event, props, estado) => {
    event.stopPropagation();
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres colocar como ${estado} a ${props.data?.nombre} ${props.data?.apellido}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        cambiarEstado(props.data, estado)
      }
    });
  };
  const cambiarEstado = async (props, estado) => {

    setIsLoading(true);
    let url = "wommer/estado"
    try {
      const response = await axios.post(url,
        {
          idUsuario: props?.idUsuario,
          estado: estado
        });
      let status = response.status;
      if (status === 200) {
        if (response.data?.ok) {
          getData()
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          });
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }

  };


  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circular"
              >
                <VisibilityIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>

      </>
    );
  };

  return (

    <>
      {userContext.state.nombreUsu !== "" ?
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              childrenAccions,
            }}
          />



        </>
        : <AccesoDenegado />}
    </>
  );
}
