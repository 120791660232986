import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography, Chip } from "@material-ui/core";
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { Grid, Box } from "@material-ui/core";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import {
  red,
  orange,
  blue,
  green,
} from "@material-ui/core/colors";
import swal from "sweetalert";
import TypographyBold from "../../components/TypographyBold";
import TimerIcon from "@material-ui/icons/Timer";
import TodayIcon from "@material-ui/icons/Today";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import HistoryIcon from "@material-ui/icons/History";
import CachedIcon from "@material-ui/icons/Cached";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Alert from '@material-ui/lab/Alert';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ReplayIcon from '@material-ui/icons/Replay';
import logoW from '../../assets/images/logoW.png'


const initFiltro = {
  fechaDesde: null,
  fechaHasta: null,
  idComercio: null,
  idEstado: null,
};

const value = [{
  "idAgendado": 1,
  "fechaServicio": "13-12-2022",
  "horaServicio": "10:00",
  "idServicio": 2,
  "servicio": "Analisis",
  "descripcion": "esto es una descripcion de actualizacion",
  "modalidad": "Presencial",
  "duracion": "Tiempo completo",
  "precio": "250000",
  "fotos": "23bfd53e-a2af-49b8-89f5-536aab8d8678.png,85a68ee0-e068-4de8-a02b-e5fb9a0a0206.png,fb60bf97-9c42-4ca0-b92a-a39059cdba92.png,940fd4ae-b896-490b-a60a-68af046144b0.png,269d6e88-12a8-41b3-917a-180d30b42801.png",
  "urlFotos": "http://api-desa.getwom.co/api/servicios/2/fotos/23bfd53e-a2af-49b8-89f5-536aab8d8678.png,http://api-desa.getwom.co/api/servicios/2/fotos/85a68ee0-e068-4de8-a02b-e5fb9a0a0206.png,http://api-desa.getwom.co/api/servicios/2/fotos/fb60bf97-9c42-4ca0-b92a-a39059cdba92.png,http://api-desa.getwom.co/api/servicios/2/fotos/940fd4ae-b896-490b-a60a-68af046144b0.png,http://api-desa.getwom.co/api/servicios/2/fotos/269d6e88-12a8-41b3-917a-180d30b42801.png",
  "idRubro": 1,
  "rubro": "TECNOLOGÍA",
  "idEspecialidad": null,
  "especialidad": null,
  "latitud": "-25.3452472",
  "longitud": "-57.4695547",
  "idDepartamento": 1,
  "departamento": "Asuncion",
  "idCiudad": 1,
  "ciudad": "Asuncion",
  "precioAgendado": "200000",
  "idUsuario": 2,
  "nombre": "Usuario",
  "apellido": "Prueba",
  "fotoPerfil": "a4373153-b60f-4ae2-99bb-66153be823cc.jpeg",
  "urlFotoPerfil": "http://api-desa.getwom.co/api//usuarios/2/foto-perfil/a4373153-b60f-4ae2-99bb-66153be823cc.jpeg",
  "valoracionPerfil": "4",
  "valoracionServicio": "2",
  "estado": "PENDIENTE",
  "idCancelacion": 1,
  "motivoCancelacion": "motivo",
  "tipoCancelacion": "WOMMER"
},]

export default function ListaServiciosAgendados() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    content: value,
  });
  const [isLoadingEstado, setIsLoadingEstado] = useState(false);
  const [isLoadingComercio, setIsLoadingComercio] = useState(false);
  const [quitarFitro, setQuitarFiltro] = useState(false);


  const [comercio, setComercio] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [listComercio, setListComercio] = useState([]);

  const [estado, setEstado] = useState({});
  const [listEstado, setListEstado] = useState([]);
  const [filtro, setFiltro] = useState(initFiltro);

  useEffect(() => {
    getPedido();
    getDashboard();
    getComercio();
    getEstado();
  }, []);


  const getFiltro = async (props) => {
    setData({ ...data, content: [] });
    setIsLoading(true);
    setQuitarFiltro(true);


    let url =
      "pedidos/adm-pedido/";
    try {
      const response = await axios.post(url, filtro);
      let status = response.status;
      if (status === 200) {
        const filtroResponse = response.data;
        console.log(filtroResponse);
        setData({
          ...data,
          content: filtroResponse?.result,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const getPedido = async () => {
    setIsLoading(true);
    setQuitarFiltro(false);
    setFiltro(initFiltro);
    setComercio({})
    setEstado({})
    let url =
      "pedidos/adm-pedido/";
    try {
      const response = await axios.post(url, initFiltro);
      let status = response.status;
      if (status === 200) {
        const pedidos = response.data;
        setData({
          ...data,
          content: pedidos?.result,
        });

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };
  const getDashboard = async () => {
    setIsLoading(true);

    let url = "pedidos/adm-pedido-dashboard/";
    try {
      const response = await axios.get(url);
      let status = response.status;
      if (status === 200) {
        const dataResponse = response.data;
        setDashboard(dataResponse?.result[0]);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getComercio = async () => {
    setIsLoadingComercio(true);

    let url = "comercios/comercio-listar/0/ACTIVADO";
    try {
      const response = await axios.get(url);
      let status = response.status;
      if (status === 200) {
        const dataResponse = response.data;
        setListComercio(dataResponse?.result);

        setIsLoadingComercio(false);
      }
    } catch (error) {
      setIsLoadingComercio(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const getEstado = async () => {
    setIsLoadingEstado(true);

    let url = "pedidos/estado-pedido/";
    try {
      const response = await axios.get(url);
      let status = response.status;
      if (status === 200) {
        const dataResponse = response.data;
        setListEstado(dataResponse?.result);

        setIsLoadingEstado(false);
      }
    } catch (error) {
      setIsLoadingEstado(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const onSelecComercio = (e, value) => {
    console.log(value);
    if (value && value !== comercio) {
      setComercio(value);
      let copyInput = { ...filtro, idComercio: value?.id_cliente };
      setFiltro(copyInput);
    }

    if (value === null) {
      setComercio({});
      let copyInput = { ...filtro, idComercio: 0 };
      setFiltro(copyInput);
    }
  };

  const onSelecEstado = (e, value) => {
    if (value && value !== estado) {
      setEstado(value);
      let copyInput = { ...filtro, idEstado: value?.idEstado };
      setFiltro(copyInput);
    }

    if (value === null) {
      setEstado({});
      let copyInput = { ...filtro, idEstado: 1 };
      setFiltro(copyInput);
    }
  };

  const handleFechaDesde = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtro, fechaDesde: "listar" };
      setFiltro(copyInput);
    } else {
      let copyInput = { ...filtro, fechaDesde: event.target.value };
      setFiltro(copyInput);
    }


  };

  const handleFechaHasta = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtro, fechaHasta: "listar" };
      setFiltro(copyInput);
    } else {
      let copyInput = { ...filtro, fechaHasta: event.target.value };
      setFiltro(copyInput);
    }
  };

  const title = (
    <>
      <Grid container spacing={2} style={{ padding: "15px 0px" }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Desde"
            type="date"
            size="small"
            onChange={(e) => handleFechaDesde(e)}
            defaultValue={filtro.fechaDesde}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Hasta"
            type="date"
            size="small"
            onChange={(e) => handleFechaHasta(e)}
            defaultValue={filtro.fechaHasta}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Autocomplete
            id="Wommers"
            size="small"
            value={comercio || ""}
            onChange={onSelecComercio}
            options={listComercio}
            getOptionLabel={(option) =>
              option?.id_cliente
                ? option?.nomb_fantasia
                : ""
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Wommers"
                name="usuario"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Autocomplete
            id="estado"
            size="small"
            value={estado || ""}
            onChange={onSelecEstado}
            options={listEstado}
            getOptionLabel={(option) =>
              option?.idEstado
                ? option?.nombre
                : ""
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Estado"
                name="estado"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            //style={{ color: lightGreen[700] }}
            startIcon={<AddIcon />}
            onClick={() => getFiltro()}
          >
            Filtrar
          </Button>
          {quitarFitro && (
            <Tooltip title="Quitar filtros" arrow>
              <IconButton
                aria-label="detalle"
                size="small"
                className={classes.iconButton}
                onClick={() => {

                  getPedido();
                }}
              >
                <CloseIcon style={{ color: red[600] }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );

  const columns = [
    {
      title: "Nro Agenda",
      field: "idAgendado",
      width: "5%",
      // hidden: true,
    },
    {
      title: "Imagen",
      field: "urlFotos",
      align: "center",

      width: "10%",
      render: (rowData) => {
        if (rowData?.urlFotos === null) {
          return (
            <img
              style={{ height: 40, borderRadius: "10%" }}
              src={`${logoW}`}
            />
          )
        } else {
          let imag = rowData?.urlFotos?.split(",")
          return (
            < img
              style={{ height: 40, borderRadius: "10%" }}
              src={`${imag ? imag[0] : rowData?.urlFotos}`}
            />
          )
        }
      }

    },
    {
      title: "Fecha",
      field: "fechaServicio",
      width: "10%",

    },
    {
      title: "Hora",
      field: "horaServicio",
      width: "10%",

    },
    {
      title: "Servicio",
      field: "servicio",

    },
    {
      title: "Modalidad",
      field: "modalidad",
      width: "15%",
    },


    {
      title: "Estado",
      field: "estado",
      width: "14%",
      align: "center",
      render: (rowData) => (
        <Alert icon={false} variant="filled" severity="success" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}>{rowData?.estado} </Alert>

      ),

    },
    {
      title: "Precio",
      field: "precio",
      width: "14%",
      align: "center",
      render: (rowData) => (
        <Alert icon={false} variant="outlined" severity="success" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}>
          {parseFloat(rowData?.precio).toLocaleString('es')}
        </Alert>

      ),
    },
  ];
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];



  const handleDetalle = (event, props) => {
    event.stopPropagation();
    history.push("./detalle/", props.data);

  };

  const childrenAccions = (props) => {
    return (
      <>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle"
              >
                <VisibilityIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>

      </>
    );
  };

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: orange[600] }}>
                <CardHeader
                  avatar={
                    <Avatar
                      variant="rounded"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <ReplayIcon
                        fontSize="large"
                        style={{ color: orange[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      PENDIENTES
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      4
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: red[600] }}>
                <CardHeader
                  avatar={
                    <Avatar
                      variant="rounded"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <CloseIcon
                        fontSize="large"
                        style={{ color: red[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      RECHAZADOS
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      2
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: blue[600] }}>
                <CardHeader
                  avatar={
                    <Avatar
                      variant="rounded"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <NotInterestedIcon
                        fontSize="large"
                        style={{ color: blue[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      INACTIVO
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      14
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: green[600] }}>
                <CardHeader
                  avatar={
                    <Avatar
                      variant="rounded"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: green[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      ACTIVOS
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      18
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MakeTables
                isLoading={isLoading}
                title={title}
                columns={columns}
                data={data.content}
                actions={actions}
                classes={classes}
                options={options}
                componentsAssets={{
                  classes,
                  //  detalle,
                  //eliminar,
                  //agregar,
                  childrenAccions,
                  // childrenToolbar,
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  );
}
