import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography, Chip } from "@material-ui/core";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import logoW from '../../assets/images/logoW.png'

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";


import { pink, lightGreen } from '@material-ui/core/colors';
import swal from 'sweetalert';

export default function ListaRegistroWommersPendiente() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ content: [] });

  const [open, setOpen] = useState(false);
  const [motivosList, setMotivosList] = useState([]);
  const [idUsuario, setIdUsuario] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData()
    getMotivos()
  }, []);
  const getMotivos = async () => {
    setIsLoading(true);
    let url = "rechazowommer/listar";
    try {
      const response = await axios.post(url);
      let status = response.status;
      if (status === 200) {
        if (status === 200) {
          const responseData = response.data;
          if (response.data?.ok) {
            setMotivosList(responseData?.result)
            setIsLoading(false);
          } else {
            setIsLoading(false);
            notificacionAlerta(response.data?.message);
          }

        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const getData = async (props) => {
    setIsLoading(true);
    let url = "wommer/listar"
    try {
      const response = await axios.post(url, {
        idUsuario: null,
        estado: "PENDIENTE"
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          setData({ ...data, content: responseData?.result });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);

        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const title = (<Button
    size='small'
    variant="outlined"
    color="primary"
    startIcon={<AddIcon />}
  //onClick={() => history.push("../nuevo-colaborador")}

  >
    Agregar
  </Button>);

  const columns = [
    {
      title: "ID",
      field: "idUsuario",
      width: "1%",
      hidden: true
    },
    {
      title: "Imagen",
      field: "urlFotoPerfil",
      align: "center",
      render: (rowData) => (rowData?.urlFotoPerfil ?
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFotoPerfil}`}
        />
        : <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${logoW}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Fecha Creación",
      field: "fechaCreacion",
      width: "15%",

    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "apellido",
      // width: "10%",

    },



    {
      title: "Documento",
      field: "nroDocumento",
      width: "12%",

    },
    {
      title: "Ciudad",
      field: "ciudad",
      width: "12%",

    },
    {
      title: "Certificado SNPP",
      field: "tituloSnpp",
      width: "15%",
      align: "center",
      render: (rowData) => (
        rowData.tituloSnpp === 1 ?
          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px" }}>
            SI
          </Alert> : <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px" }}>
            NO
          </Alert>

      ),

    },

    {
      title: "Factura",
      field: "emiteFactura",
      width: "10%",
      align: "center",
      render: (rowData) => (
        rowData.emiteFactura === 1 ?
          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px" }}>
            SI
          </Alert> : <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px" }}>
            NO
          </Alert>

      ),

    },
  ];
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];


  const handleCambiarEstado = (event, props, estado) => {
    event.stopPropagation();
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres colocar como ${estado} a ${props.data?.nombre} ${props.data?.apellido}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        cambiarEstado(props.data, estado)
      }
    });
  };
  const cambiarEstado = async (props, estado) => {

    setIsLoading(true);
    let url = "wommer/estado"
    try {
      const response = await axios.post(url,
        {
          idUsuario: props?.idUsuario,
          estado: estado
        });
      let status = response.status;
      if (status === 200) {

        if (response.data?.ok) {
          getData()
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          });
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }

  };

  const handleDetalle = (event, props) => {
    event.stopPropagation();
    history.push("./detalle/", props.data);

  };

  const handleRechazar = (event, props) => {
    event.stopPropagation();
    setIdUsuario(props.data?.idUsuario);
    setOpen(true)

  };

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle"
              >
                <VisibilityIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleCambiarEstado(e, props, "ACTIVO")}
            avatar={
              <Avatar variant="circular"
                sx={{ bgcolor: "red" }} >
                <CheckIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Activar"
            variant="outlined"
            color="secondary"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleRechazar(e, props)}
            avatar={
              <Avatar variant="circular"
                sx={{ bgcolor: "red" }} >
                <CloseIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Rechazar"
            variant="outlined"
            color="default"
          />
        </Box>
      </>
    );
  };

  function MotivoRechazo(props) {
    const { open, onClose, usuario, motivos } = props;
    console.log(props)
    const [motivoSelect, setMotivoSelect] = useState({});

    const onSelectMotivo = (e, value) => {
      if (value && value?.idMotivo !== motivoSelect?.idMotivo) {
        setMotivoSelect(value)
      }

      if (value === null) {
        setMotivoSelect({})
      }
    };
    const handleGuardar = async () => {
      onClose();
      let url = "rechazowommer/rechazar";

      let newObject = {
        idUsuario: usuario,
        idMotivo: motivoSelect?.idMotivo,
      };

      try {
        const response = await axios.post(url, newObject);
        let status = response.status;
        if (status === 200) {

          if (response.data?.ok) {
            swal("¡OPERACIÓN EXITOSA!", {
              icon: "success",
              buttons: false,
              timer: 1500,
            });
            history.go(0);
          } else {
            setIsLoading(false);
            notificacionAlerta(response.data?.message);
          }

  
        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      }
    };

    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          MOTIVO DE RECHAZO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Autocomplete
                size="small"
                value={motivoSelect || ""}
                onChange={onSelectMotivo}
                options={motivos}
                getOptionLabel={(option) =>
                  option.nombre ? option.nombre : ""
                }
                renderOption={(option) => (
                  <React.Fragment>{option?.nombre}</React.Fragment>
                )}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Motivo"
                    name="Motivo"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose();
                }}
              >
                Salir
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={Object.keys(motivoSelect).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar();
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }


  return (

    <>
      {userContext.state.nombreUsu !== "" ?
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              childrenAccions,
            }}
          />



        </>
        : <AccesoDenegado />}
      {open && <MotivoRechazo open={open} onClose={handleClose} usuario={idUsuario} motivos={motivosList} />}


    </>
  );
}
