import React, { useState, memo, useContext } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Drawer from "@material-ui/core/Drawer";
import Chip from "@material-ui/core/Chip";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import logo from "../assets/images/logo.png";
import AvatarIcon from "../assets/images/avatar.png";
import Avatar from "@material-ui/core/Avatar";
import Exit from "@material-ui/icons/ExitToApp";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import GroupIcon from "@material-ui/icons/Group";
import SearchIcon from "@material-ui/icons/Search";
import UserContext from "../utils/user/UserContext";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PeopleIcon from "@material-ui/icons/People";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DataUsageIcon from '@material-ui/icons/DataUsage';

import ListIcon from '@material-ui/icons/List';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import SendIcon from '@material-ui/icons/Send';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { containerCSS } from "react-select/dist/index-ea9e225d.cjs.prod";
import HistoryIcon from '@material-ui/icons/History';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import CategoryIcon from '@material-ui/icons/Category';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TypographyBold from "./TypographyBold";
import BeenhereIcon from '@material-ui/icons/Beenhere';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    //  background: "rgb(96, 235, 82)",
    //background: "linear-gradient(140deg,rgba(11, 190, 113, 1) 0%,rgb(255 ,234 , 234 ) 100%)",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    ...theme.mixins.toolbar,
    backgroundColor: "#07a896",
  },

  appBar: {
    backgroundColor: "#07a896",
    color: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  active: {
    backgroundColor: "rgb(0 74 173 / 9%)",
  },

  activeModule: {

    backgroundColor: "rgb(240 159 84  / 30% )",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  agrupador: {
    margin: theme.spacing(2, 1, 1, 2),
    color: "#6b6e71",
  },
}));

const drawerWidth = 230;

function NavBar() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [open, setOpen] = useState(true);
  const [openProyecto, setOpenProyecto] = useState(false);
  const [openColaborador, setOpenColaborador] = useState(false);
  const [openVisitas, setOpenVisitas] = useState(false);
  const [openComercio, setOpenComerio] = useState(false);
  const [openConfiguracion, setOpenConfiguracion] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [auth] = useState(true);
  const openMenu = Boolean(anchorEl);
  const pathName = window.location.pathname.split("/");
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const envioFormulario = (formulario) => {
    history.push("/" + formulario);
  };

  const handleClickColaborador = () => {
    setOpenColaborador(!openColaborador);
  };

  const handleClickVisitas = () => {
    setOpenVisitas(!openVisitas);
  };

  const handleClickComercio = () => {
    setOpenComerio(!openComercio);
  };

  const handleClickConfiguracion = () => {
    setOpenConfiguracion(!openConfiguracion);
  };
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{
          boxShadow:
            "0px 1px 1px -1px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 4%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {/*
            
            open && (
              <IconButton color="inherit" onClick={handleDrawerClose}>
                <MenuOpenIcon />
              </IconButton>
            )
            */}
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}></ListItem>

          {auth && (
            <div>
              <List
                className={classes.root}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItem button onClick={handleMenu}>
                  <ListItemAvatar style={{ minWidth: 50 }}>
                    <Avatar alt="Cindy Baker" src={AvatarIcon} />
                  </ListItemAvatar>

                  <ListItemText primary={userContext.state.nombre} />
                </ListItem>
              </List>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={openMenu}
                onClose={handleClose}
              >
                <Divider />

                <MenuItem
                  onClick={(e) => {
                    userContext.clearUser();
                    history.push("/acceder");
                  }}
                >
                  <ListItemIcon onClick={() => handleClose()}>
                    <Exit color="secondary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img width={70} alt="Logo" src={logo} />
          <Chip
            variant="default"
            label="V1.0.0"
            size="small"
            style={{
              height: 15,
              fontSize: 8,
              marginLeft: 5,
              alignSelf: "end",
              marginBottom: 10,

              backgroundColor: "fff",
            }}
          />
        </div>
        <Divider />


        <>

          <List>

            <ListItem
              button
              onClick={() => history.push("/")}
              className={pathName?.[1] === "" && classes.active}
            >
              <ListItemIcon style={{ minWidth: 30 }}>
                <DashboardIcon fontSize="small" style={{ color: "#07a896" }} />
              </ListItemIcon>
              <ListItemText secondary="Dashboard" />
            </ListItem>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Wommers Reportados
              </TypographyBold>

              <ListItem
                button
                onClick={() => history.push("/reportados/")}
                className={pathName?.[2] === "womers-pendientes" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Lista Reportados" />
              </ListItem>


            </List>


            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Servicios
              </TypographyBold>

              <ListItem
                button
                onClick={() => history.push("/servicios/servicios-pendientes/")}
                className={pathName?.[2] === "servicios-pendientes" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Servicios pendientes" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/servicios/servicios-activos/")}
                className={pathName?.[2] === "servicios-activos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Servicios activos" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/servicios/servicios-inactivos/")}
                className={pathName?.[2] === "servicios-inactivos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Servicios inactivos" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/servicios/servicios-rechazados/")}
                className={pathName?.[2] === "servicios-rechazados" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Servicios rechazados" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Servicios agendados
              </TypographyBold>

              <ListItem
                button
                onClick={() => history.push("/servicios-agendados/lista-servicios-agendados")}
                className={pathName?.[2] === "lista-servicios-agendados" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Lista servicios agendados" />
              </ListItem>


            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Registros Wommers
              </TypographyBold>

              <ListItem
                button
                onClick={() => history.push("/registros/womers-pendientes/")}
                className={pathName?.[2] === "womers-pendientes" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Registros pendientes" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/registros/womers-activos/")}
                className={pathName?.[2] === "womers-activos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Registros activos" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/registros/womers-inactivos/")}
                className={pathName?.[2] === "womers-inactivos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Registros inactivos" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/registros/womers-rechazados/")}
                className={pathName?.[2] === "womers-rechazados" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Registros rechazados" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Rubros
              </TypographyBold>
              <ListItem
                button
                onClick={() => history.push("/rubros/nuevo-rubro/")}
                className={pathName?.[2] === "nuevo-rubro" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear rubro" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/rubros/lista-rubros/")}
                className={pathName?.[2] === "lista-rubros" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar rubros" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Motivo rechazo del wommers
              </TypographyBold>
              <ListItem
                button
                onClick={() => history.push("/motivo/nuevo-motivo-rechazo/")}
                className={pathName?.[2] === "nuevo-motivo-rechazo" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear motivo" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/motivo/lista-motivo/")}
                className={pathName?.[2] === "lista-motivo" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar motivos" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Motivo rechazo del servicio
              </TypographyBold>
              <ListItem
                button
                onClick={() => history.push("/motivo-servicio/nuevo-motivo-rechazo-servicio/")}
                className={pathName?.[2] === "nuevo-motivo-rechazo-servicio" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear motivo" />
              </ListItem>

              <ListItem
                button
                onClick={() => history.push("/motivo-servicio/lista-motivo-servicio/")}
                className={pathName?.[2] === "lista-motivo-servicio" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#07a896" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar motivos" />
              </ListItem>
            </List>

          </List>


        </>

      </Drawer>
    </>
  );
}

export default memo(NavBar);
