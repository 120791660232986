import NotFound from "./components/NotFound";
import Login from "./page/Login";
import Tablero from "./page/Tablero";
import Colaboradores from "./page/colaboradores/Colaboradores";
import NuevoColaborador from "./page/colaboradores/NuevoColaborador";
import ListaColaboradores from "./page/colaboradores/ListaColaboradores";
import ListaRegistroWommersActivo from "./page/registros-womers/ListaRegistroWommersActivo";
import RegistrosWomers from "./page/registros-womers/RegistrosWomers";
import DetalleRegistro from "./page/registros-womers/DetalleRegistro";
import RegisrtoWommers from "./page/RegisrtoWommers";
import ListaRubro from "./page/rubros/ListaRubro";
import Rubros from "./page/rubros/Rubros";
import NuevoRubro from "./page/rubros/NuevoRubro";
import ActualizarRubro from "./page/rubros/ActualizarRubro";
import DetalleRubro from "./page/rubros/DetalleRubro";
import ListaRegistroWommersPendiente from "./page/registros-womers/ListaRegistroWommersPendiente";
import ListaRegistroWommersRechazados from "./page/registros-womers/ListaRegistroWommersRechazados";
import ListaRegistroWommersInactivo from "./page/registros-womers/ListaRegistroWommersInactivo";
import ListaMotivoRechazo from "./page/motivo-rechazo/ListaMotivoRechazo";
import NuevoMotivoRechazo from "./page/motivo-rechazo/NuevoMotivoRechazo";
import EditarMotivoRechazo from "./page/motivo-rechazo/EditarMotivoRechazo";
import Motivo from "./page/motivo-rechazo/Motivo";
import ListaReportados from "./page/reportados/ListaReportados";
import DetalleReportados from "./page/reportados/DetalleReportados";
import ListaServiciosActivo from "./page/servicios/ListaServiciosActivo";
import DetalleServicios from "./page/servicios/DetalleServicios";
import ListaServiciosPendientes from "./page/servicios/ListaServiciosPendientes";
import ListaServiciosRechazados from "./page/servicios/ListaServiciosRechazados";
import ListaServiciosInactivo from "./page/servicios/ListaServiciosInactivo";
import MotivoServicios from "./page/motivo-rechazo-servicios/MotivoServicios";
import ListaMotivoRechazoServicio from "./page/motivo-rechazo-servicios/ListaMotivoRechazoServicio";
import NuevoMotivoRechazoServicio from "./page/motivo-rechazo-servicios/NuevoMotivoRechazoServicio";
import EditarMotivoRechazoServicio from "./page/motivo-rechazo-servicios/EditarMotivoRechazoServicio";
import ListaServiciosAgendados from "./page/servicios-agendados/ListaServiciosAgendados";
import ServiciosAgendados from "./page/servicios-agendados/ServiciosAgendados";

export const routes = [
  {
    path: "/",
    label: "Inicio",
    component: Tablero,
    routes: [
      {
        path: "/acceder",
        label: "INICIAR SESION",
        component: Login,
      },
      {
        path: "/registro-wommers",
        label: "Registro de Wommers",
        component: RegisrtoWommers,
      },
      {
        path: "/reportados",
        label: "Wommers Reportados",
        component: ListaReportados,
        routes: [
          {
            path: "/detalle/",
            label: "Detalle reportados",
            component: DetalleReportados,
          },
        ]
      },

      //rubro
      {
        path: "/rubros/",
        label: "Rubros",
        component: Rubros,
        routes: [
          {
            path: "/nuevo-rubro/",
            label: "Nuevo rubro",
            component: NuevoRubro,
          },
          {
            path: "/lista-rubros/",
            label: "Lista de rubros",
            component: ListaRubro,
            routes: [
              {
                path: "/editar-rubro/",
                label: "Editar rubro",
                component: ActualizarRubro,
              },
              {
                path: "/detalle-rubro/",
                label: "Detalle rubro",
                component: DetalleRubro,
              },
            ]
          },
        ],
      },
      //servicios agendados
      {
        path: "/servicios-agendados/",
        label: "Servicios agendados",
        component: ServiciosAgendados,
        routes: [
          {
            path: "/lista-servicios-agendados/",
            label: "Lista de servicios agendados",
            component: ListaServiciosAgendados,
            routes: [
              {
                path: "/editar-motivo-servicio/",
                label: "Editar motivo",
                component: EditarMotivoRechazoServicio,
              },

            ]
          },
        ],
      },

      //motivo
      {
        path: "/motivo/",
        label: "Motivos del wommers",
        component: Motivo,
        routes: [
          {
            path: "/nuevo-motivo-rechazo/",
            label: "Nuevo motivo",
            component: NuevoMotivoRechazo,
          },
          {
            path: "/lista-motivo/",
            label: "Lista de motivos",
            component: ListaMotivoRechazo,
            routes: [
              {
                path: "/editar-motivo/",
                label: "Editar motivo",
                component: EditarMotivoRechazo,
              },

            ]
          },
        ],
      },

      //motivo servicio
      {
        path: "/motivo-servicio/",
        label: "Motivos del servicio",
        component: MotivoServicios,
        routes: [
          {
            path: "/nuevo-motivo-rechazo-servicio/",
            label: "Nuevo motivo",
            component: NuevoMotivoRechazoServicio,
          },
          {
            path: "/lista-motivo-servicio/",
            label: "Lista de motivos",
            component: ListaMotivoRechazoServicio,
            routes: [
              {
                path: "/editar-motivo-servicio/",
                label: "Editar motivo",
                component: EditarMotivoRechazoServicio,
              },

            ]
          },
        ],
      },

      //Colaboradores
      {
        path: "/colaboradores/",
        label: "Colaboradores",
        component: Colaboradores,
        routes: [
          {
            path: "/nuevo-colaborador/",
            label: "Nuevo colaborador",
            component: NuevoColaborador,
          },
          {
            path: "/lista-colaboradores/",
            label: "Lista de colaborador",
            component: ListaColaboradores,
          },
        ],
      },

      //Lista registro wommers
      {
        path: "/registros/",
        label: "Registros",
        component: RegistrosWomers,
        routes: [
          {
            path: "/womers-activos/",
            label: "Womers Activos",
            component: ListaRegistroWommersActivo,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleRegistro,
              },
            ],
          },
          {
            path: "/womers-pendientes/",
            label: "Wommers pendientes",
            component: ListaRegistroWommersPendiente,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleRegistro,
              },
            ],
          },
          {
            path: "/womers-rechazados/",
            label: "Wommers rechazados",
            component: ListaRegistroWommersRechazados,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleRegistro,
              },
            ],
          },
          {
            path: "/womers-inactivos/",
            label: "Wommers inactivos",
            component: ListaRegistroWommersInactivo,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleRegistro,
              },
            ],
          },
        ],
      },

      //Lista registro servicios
      {
        path: "/servicios/",
        label: "Servicios",
        component: RegistrosWomers,
        routes: [
          {
            path: "/servicios-activos/",
            label: "Servicios Activos",
            component: ListaServiciosActivo,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle del servicio",
                component: DetalleServicios,
              },
            ],
          },
          {
            path: "/servicios-pendientes/",
            label: "Servicios pendientes",
            component: ListaServiciosPendientes,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleServicios,
              },
            ],
          },
          {
            path: "/servicios-rechazados/",
            label: "Servicios rechazados",
            component: ListaServiciosRechazados,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleServicios,
              },
            ],
          },
          {
            path: "/servicios-inactivos/",
            label: "Servicios inactivos",
            component: ListaServiciosInactivo,
            routes: [
              {
                path: "/detalle/",
                label: "Lista de registros Wommers",
                component: DetalleServicios,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "*",
    label: "Error en la página",
    component: NotFound,
  },
];
