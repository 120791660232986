import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Avatar, Box } from "@material-ui/core";
import { BotonVerde, BotonGris } from "../assets/styles/StyledButtons";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { alertWarningError, notificacionAlerta } from "../components/Notificaciones";
import Upload from "rc-upload";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserContext from "../utils/user/UserContext";
import swal from 'sweetalert';
import logo from "../assets/images/logoW.png";
import frontal from "../assets/images/ci_frontal.png";
import dorsal from "../assets/images/ci_dorsal.png";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TypographyBold from "../components/TypographyBold";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import BackdropCustom from "../components/BackdropCustom";

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";

const inicialValue = {
  email: "",
  password: "",
  nombre: "",
  apellido: "",
  fechaNacimiento: "",
  documento: "",
  celular: "",
  idDepartamento: 0,
  idCiudad: 0,
  idRubro: 0,
  tituloSnpp: false,
  emiteFactura: false
}
const defaultValue = {
  email: false,
  password: false,
  nombre: false,
  apellido: false,
  fechaNacimiento: false,
  idDepartamento: false,
  idCiudad: false,
  idRubro: false,
  documento: false,
  ciFrente: false,
  ciDorso: false,
  selfie: false,
};

export default function RegisrtoWommers() {
  const history = useHistory();
  const classes = useStyles();

  const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;

  const instance = axios.create({
    baseURL: origin,
    timeout: 10000,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingRubro, setIsLoadingRubro] = useState(false);
  const [isLoadingCiudad, setIsLoadingCiudad] = useState(false);
  const [isLoadingDepartamento, setIsLoadingDepartamento] = useState(false);
  const [departamento, setDepartamento] = useState({});
  const [ciudad, setCiudad] = useState({});
  const [rubro, setRubro] = useState({});
  const [departamentoList, setDepartamentoList] = useState({ content: [] });
  const [rubroList, setRubroList] = useState({ content: [] });
  const [ciudadList, setCiudadList] = useState({ content: [] });
  const [registro, setRegistro] = useState(inicialValue);
  const [fotoFrenteCI, setFotoFrenteCI] = useState({});
  const [fotoDorsoCI, setFotoDorsoCI] = useState({});
  const [fotoSelfie, setFotoSelfie] = useState({});
  const [fotoPerfil, setFotoPerfil] = useState({});
  const [textValidacion, setTextValidacion] = useState(defaultValue);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getSteps() {
    return ['Registro', 'Datos', 'Documentos', 'Selfie', 'SNPP'];
  }

  const handleNextRegistro = () => {

    if (registro.email !== "" && registro.password !== "") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido
    let copy = { ...textValidacion };

    if (registro.email === "") {
      copy.email = true;
    } else {
      copy.email = false;
    }
    if (registro.password === "") {
      copy.password = true;
    } else {
      copy.password = false;
    }

    setTextValidacion(copy);

  };

  const handleNextDatosPersonales = () => {

    if (registro.nombre !== "" && registro.apellido !== "" && registro.fechaNacimiento !== "" && registro.celular !== "" && registro.idDepartamento > 0 && registro.idCiudad > 0 && registro.idRubro > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido
    let copy = { ...textValidacion };

    if (registro.nombre === "") {
      copy.nombre = true;
    } else {
      copy.nombre = false;
    }
    if (registro.apellido === "") {
      copy.apellido = true;
    } else {
      copy.apellido = false;
    }
    if (registro.fechaNacimiento === "") {
      copy.fechaNacimiento = true;
    } else {
      copy.fechaNacimiento = false;
    }
    if (registro.celular === "") {
      copy.celular = true;
    } else {
      copy.celular = false;
    }
    if (registro.idDepartamento === 0) {
      copy.idDepartamento = true;
    } else {
      copy.idDepartamento = false;
    }
    if (registro.idCiudad === 0) {
      copy.idCiudad = true;
    } else {
      copy.idCiudad = false;
    }
    if (registro.idRubro === 0) {
      copy.idRubro = true;
    } else {
      copy.idRubro = false;
    }

    setTextValidacion(copy);
    console.log(copy);

  };

  const handleNextDocumento = () => {

    if (registro.documento !== "" && Object.keys(fotoFrenteCI).length > 0 && Object.keys(fotoDorsoCI).length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      getSNPPCertificado()
    }

    //validamos cada input requerido
    let copy = { ...textValidacion };

    if (registro.documento === "") {
      copy.documento = true;
    } else {
      copy.documento = false;
    }
    if (Object.keys(fotoFrenteCI).length === 0) {
      copy.ciFrente = true;
    } else {
      copy.ciFrente = false;
    }
    if (Object.keys(fotoDorsoCI).length === 0) {
      copy.ciDorso = true;
    } else {
      copy.ciDorso = false;
    }
    setTextValidacion(copy);

  };
  const handleNextSelfie = () => {

    if (Object.keys(fotoSelfie).length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido
    let copy = { ...textValidacion };

    if (Object.keys(fotoSelfie).length === 0) {
      copy.selfie = true;
    } else {
      copy.selfie = false;
    }
    setTextValidacion(copy);

  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return Registro();
      case 1:
        return DatosPersonales();
      case 2:
        return Documentos();
      case 3:
        return Selfie();
      case 4:
        return SNPP();
      default:
        return 'Unknown stepIndex';
    }
  }

  const Registro = (props) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="Correo"
              name="Correo"
              label="Correo"
              value={registro.email}
              onChange={(value) => handleChangeCorreo(value)}
              type="email"
              fullWidth
              error={textValidacion.email}
              helperText={
                textValidacion.email ? "Campo obligatorio" : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              variant="outlined"
              id="Contraseña"
              name="Contraseña"
              label="Contraseña"
              value={registro.password}
              onChange={(value) => handleChangePassword(value)}
              type="password"
              fullWidth
              error={textValidacion.password}
              helperText={
                textValidacion.password ? "Campo obligatorio" : null
              }
            />
          </Grid>


        </Grid>
        <Box pt={4} >


          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
            size="small"
          >
            Anterior
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={handleNextRegistro}>
            Registrarse
          </Button>
        </Box>
      </>
    );
  };

  const DatosPersonales = (props) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="nombre"
              name="nombre"
              label="Nombre"
              value={registro.nombre}
              onChange={(value) => handleChangeNombre(value)}
              type="text"
              fullWidth
              error={textValidacion.nombre}
              helperText={
                textValidacion.nombre ? "Campo obligatorio" : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              variant="outlined"
              id="apellido"
              name="apellido"
              label="Apellido"
              value={registro.apellido}
              onChange={(value) => handleChangeApellido(value)}
              type="text"
              fullWidth
              error={textValidacion.apellido}
              helperText={
                textValidacion.apellido ? "Campo obligatorio" : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              variant="outlined"
              id="celfechaular"
              name="fecha"
              label="Fecha Nacimiento"
              InputLabelProps={{
                shrink: true,
              }}
              value={registro.fechaNacimiento}
              onChange={(value) => handleChangeFechaNacimiento(value)}
              type="date"
              fullWidth
              error={textValidacion.fechaNacimiento}
              helperText={
                textValidacion.fechaNacimiento ? "Campo obligatorio" : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              variant="outlined"
              id="celular"
              name="celular"
              label="Celular"

              value={registro.celular}
              onChange={(value) => handleChangeNroContacto(value)}
              type="text"
              fullWidth
              error={textValidacion.celular}
              helperText={
                textValidacion.celular ? "Campo obligatorio" : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Autocomplete
              size="small"
              value={departamento || ""}
              onChange={onSelectDepartamento}
              options={departamentoList?.content}
              getOptionLabel={(option) =>
                option.nombre ? option.nombre : ""
              }
              renderOption={(option) => (
                <React.Fragment>{option?.nombre}</React.Fragment>
              )}
              loading={isLoadingDepartamento}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Departamento"
                  name="Departamento"
                  error={textValidacion.idDepartamento}
                  helperText={
                    textValidacion.idDepartamento ? "Campo obligatorio" : null
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingDepartamento ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Autocomplete
              size="small"
              value={ciudad || ""}
              onChange={onSelecCiudad}
              options={ciudadList?.content}
              getOptionLabel={(option) =>
                option.ciudad ? option.ciudad : ""
              }
              renderOption={(option) => (
                <React.Fragment>{option?.ciudad}</React.Fragment>
              )}
              loading={isLoadingCiudad}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Ciudad"
                  name="Ciudad"
                  error={textValidacion.idCiudad}
                  helperText={
                    textValidacion.idCiudad ? "Campo obligatorio" : null
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingCiudad ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Autocomplete
              size="small"
              value={rubro || ""}
              onChange={onSelectRubro}
              options={rubroList?.content}
              getOptionLabel={(option) =>
                option.nombre ? option.nombre : ""
              }
              renderOption={(option) => (
                <React.Fragment>{option?.nombre}</React.Fragment>
              )}
              loading={isLoadingRubro}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Rubro"
                  name="Rubro"
                  error={textValidacion.idRubro}
                  helperText={
                    textValidacion.idRubro ? "Campo obligatorio" : null
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingRubro ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Emite Factura</FormLabel>
              <RadioGroup row name="emite_factura" value={registro.emiteFactura ? "SI" : "NO"} onChange={handleChangeEmiteFactura}>
                <FormControlLabel value="SI" control={<Radio />} label="SI" />
                <FormControlLabel value="NO" control={<Radio />} label="NO" />
              </RadioGroup>
            </FormControl>
          </Grid>


        </Grid>
        <Box pt={4} >

          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
            size="small"
          >
            Anterior
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={handleNextDatosPersonales}>
            Siguiente
          </Button>
        </Box>
      </>
    );
  };

  const Documentos = (props) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="documento"
              name="Documento"
              label="Nro. Documento"
              value={registro.documento}
              onChange={(value) => handleChangeDocumento(value)}
              type="text"
              fullWidth
              error={textValidacion.documento}
              helperText={
                textValidacion.documento ? "Campo obligatorio" : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} style={{ textAlign: "center", alignSelf: "center" }}>
            {textValidacion.ciFrente && <InputLabel shrink style={{ textAlign: "center", color: "red", fontSize: 16 }} >
              Foto obligatorio
            </InputLabel>}
            {Object.keys(fotoFrenteCI).length === 0 ? (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Avatar variant="rounded" alt="Foto Frente" style={{ width: 170, height: 100, }} src={frontal} />
              </Box>
            ) : (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Badge
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <Tooltip title="Eliminar imagen" aria-label="eleminar">
                      <IconButton edge="end" variant="contained" aria-label="delete" onClick={(event) => handleEliminarImagenFrente(event)}>
                        <DeleteIcon style={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Avatar alt="Foto Frente" src={`data:${fotoFrenteCI?.type};base64,${fotoFrenteCI?.data}`} style={{ width: 100, height: 100, }} />
                </Badge>
              </Box>
            )}

            <Upload {...uploaderPropsFrente}>
              <Button
                size="small"
                //  startIcon={<PhotoCameraIcon />}
                //startIcon={PublishIcon}
                variant="contained"
                color="primary"
                children="Foto Frente  "
              />
            </Upload>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} style={{ textAlign: "center", alignSelf: "center" }}>
            {textValidacion.ciDorso && <InputLabel shrink style={{ textAlign: "center", color: "red", fontSize: 16 }} >
              Foto obligatorio
            </InputLabel>}
            {Object.keys(fotoDorsoCI).length === 0 ? (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Avatar variant="rounded" alt="Foto Dorso" style={{ width: 170, height: 100, }} src={dorsal} />


              </Box>
            ) : (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  badgeContent={
                    <Tooltip title="Eliminar imagen" aria-label="eleminar">
                      <IconButton
                        edge="end"
                        variant="contained"
                        aria-label="delete"
                        onClick={(event) => handleEliminarImagenDorso(event)}
                      >
                        <DeleteIcon style={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Avatar alt="Foto categoría" src={`data:${fotoDorsoCI?.type};base64,${fotoDorsoCI?.data}`} style={{ width: 100, height: 100, }} />

                </Badge>

              </Box>
            )}


            <Upload {...uploaderPropsDorso}>
              <Button size="small" variant="contained" color="primary" children="Foto Dorso  " />
            </Upload>

          </Grid>


        </Grid>
        <Box pt={4} >

          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
            size="small"
          >
            Anterior
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={handleNextDocumento}>
            Siguiente
          </Button>
        </Box>
      </>
    );
  };


  const Selfie = (props) => {
    return (
      <>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", alignSelf: "center" }}>
            <Box mb={1}>
              <Typography
                variant="body2"
                display="block"
                color="secondary"
              >
                Está información es privada, es para controlar que la cédula presentada, realmente te pertenece para brindar mayor seguridad.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} style={{ textAlign: "center", alignSelf: "center" }}>
            {textValidacion.selfie && <InputLabel shrink style={{ textAlign: "center", color: "red", fontSize: 16 }} >
              Foto obligatorio
            </InputLabel>}
            {Object.keys(fotoSelfie).length === 0 ? (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Avatar variant="rounded" alt="Foto Selfie" style={{ width: 170, height: 100, }} >
                  <PhotoCameraIcon fontSize="large" />
                </Avatar >

              </Box>
            ) : (
              <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  badgeContent={
                    <Tooltip title="Eliminar imagen" aria-label="eleminar">
                      <IconButton
                        edge="end"
                        variant="contained"
                        aria-label="delete"
                        onClick={(event) => handleEliminarImagenSelfie(event)}
                      >
                        <DeleteIcon style={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Avatar alt="Foto categoría" src={`data:${fotoSelfie?.type};base64,${fotoSelfie?.data}`} style={{ width: 100, height: 100, }} />

                </Badge>

              </Box>
            )}


            <Upload {...uploaderPropsSelfie}>
              <Button
                size="small"
                //  startIcon={<PhotoCameraIcon />}
                //startIcon={PublishIcon}
                variant="contained"
                color="primary"
                children="Foto Sefie  "
              />
            </Upload>
          </Grid>
        </Grid>
        <Box pt={4} >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
            size="small"
          >
            Anterior
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={handleNextSelfie}>
            Siguiente
          </Button>
        </Box>
      </>
    );
  };

  const SNPP = (props) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={2}
        >

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {registro.tituloSnpp ? "Hemos verificado que tienes estudios aprobados en el SNPP tu cuenta contará con un distintivo por dicho logro." : "No cuentas con estudios aprobados en el SNPP."}

          </Grid>

        </Grid>
        <Box pt={4} >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
            size="small"
          >
            Anterior
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={() => handleGuardar()}>
            Finalizar
          </Button>
        </Box>
      </>
    );
  };

  useEffect(() => {
    getDepartamento();
    getRubro();

  }, []);

  const uploaderPropsFrente = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader();
      console.log(file);

      const duplicado = fotoFrenteCI?.file?.uid === file.uid

      console.log(duplicado);
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file);
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {

          let base64 = reader.result.split(";base64,");
          let documentoBase64 = base64[1];
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          };

          console.log(fotoFrenteCI)
          setFotoFrenteCI(imagen)

        };

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file");
        };
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err);
    },
    capture: "josue",
  };

  const uploaderPropsDorso = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader();
      console.log(file);

      const duplicado = fotoDorsoCI?.file?.uid === file.uid

      console.log(duplicado);
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file);
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {

          let base64 = reader.result.split(";base64,");
          let documentoBase64 = base64[1];
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          };

          console.log(fotoDorsoCI)
          setFotoDorsoCI(imagen)

        };

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file");
        };
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err);
    },
    capture: "josue",
  };
  const uploaderPropsSelfie = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader();
      console.log(file);

      const duplicado = fotoSelfie?.file?.uid === file.uid

      console.log(duplicado);
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file);
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {

          let base64 = reader.result.split(";base64,");
          let documentoBase64 = base64[1];
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          };

          console.log(fotoSelfie)
          setFotoSelfie(imagen)

        };

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file");
        };
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err);
    },
    capture: "josue",
  };

  const getDepartamento = async () => {
    setIsLoadingDepartamento(true);
    let url = "common/departamento-listar";

    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(url);
      let status = response.status;
      if (status === 200) {
        const responseData = response.data?.result;
        setDepartamentoList({ ...departamentoList, content: responseData });

        setIsLoadingDepartamento(false);
      }
    } catch (error) {
      setIsLoadingDepartamento(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getCiudad = async (props) => {
    setIsLoadingCiudad(true);
    let url = "common/ciudad-listar";
    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(url, {
        idDepartamento: props
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data?.result;
        setCiudadList({ ...ciudadList, content: responseData });

        setIsLoadingCiudad(false);
      }
    } catch (error) {
      setIsLoadingCiudad(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };
  const getSNPPCertificado = async () => {

    let url = "snpprelacion/consultar";
    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(url, {
        idRubro: registro.idRubro,
        cedula: registro.documento,
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data?.result;
        if (responseData?.snpp) {
          let copyInput = { ...registro, tituloSnpp: true };
          setRegistro(copyInput);
        } else {
          let copyInput = { ...registro, tituloSnpp: false };
          setRegistro(copyInput);
        }
      }
    } catch (error) {

      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getRubro = async () => {
    setIsLoadingRubro(true);
    let url = "rubros/listar";
    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(url, {
        estado: "ACTIVO"
      });
      let status = response.status;
      if (status === 200) {
        const rubroResponse = response.data;
        setRubroList({ ...rubroList, content: rubroResponse?.result });
        setIsLoadingRubro(false);
      }
    } catch (error) {
      setIsLoadingRubro(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const handleGuardar = async () => {
    setIsLoading(true);
    let url = "auth/registro-wommer";

    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(url, registro);
      let status = response.status;
      if (status === 201) {
        console.log(response.data?.ok)
        if (response.data?.ok) {

          if (Object.keys(fotoFrenteCI).length > 0) {
            handleEnviarFotoCI(response.data?.result?.idUsuario)
          }
          if (Object.keys(fotoSelfie).length > 0) {
            handleEnviarSelfie(response.data?.result?.idUsuario)
          }
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);

        }



      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const handleEnviarFotoCI = async (props) => {

    let barArray = [props, `'${fotoFrenteCI?.file},${fotoDorsoCI?.file}'`];
    const formData = new FormData();
    // formData.append("imgs", JSON.stringify(barArray));
    formData.append("imgs", fotoFrenteCI?.file);
    formData.append("imgs", fotoDorsoCI?.file);
    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(
        `wommer/foto-documento/${props}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let status = response.status;
      if (status === 201) {
        setIsLoading(false);
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        });
        ;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }

  };

  const handleEnviarSelfie = async (props) => {

    const formData = new FormData();
    formData.append("imgs", fotoSelfie?.file);
    try {
      axios.defaults.headers.common["Authorization"] = "";
      const response = await instance.post(
        `wommer/foto-selfie/${props}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let status = response.status;
      if (status === 201) {
        setIsLoading(false);
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        });
        handleNext()
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }

  };

  /** SETEAMOS LOS CAMPOS  */

  const handleChangeNombre = (event) => {
    let copyInput = { ...registro, nombre: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangeApellido = (event) => {
    let copyInput = { ...registro, apellido: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangeFechaNacimiento = (event) => {
    let copyInput = { ...registro, fechaNacimiento: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangeCorreo = (event) => {
    let copyInput = { ...registro, email: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangePassword = (event) => {
    let copyInput = { ...registro, password: event.target.value };
    setRegistro(copyInput);
  }

  const handleChangeNroContacto = (event) => {
    let copyInput = { ...registro, celular: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangeEmiteFactura = (event) => {
    let copyInput = { ...registro, emiteFactura: event.target.value === "SI" ? true : false };
    setRegistro(copyInput);
  };

  const onSelectDepartamento = (e, value) => {

    if (value && value?.idDepartamento !== registro.idDepartamento) {
      setDepartamento(value)
      let copyInput = { ...registro, idDepartamento: value?.idDepartamento };
      setRegistro(copyInput);
      setCiudad({})
      getCiudad(value?.idDepartamento)

    }

    if (value === null) {
      setDepartamento({})
      setCiudad({})
      let copyInput = { ...registro, idDepartamento: 0 };
      setRegistro(copyInput);
    }
  };

  const onSelecCiudad = (e, value) => {
    if (value && value?.idCiudad !== registro?.idCiudad) {
      setCiudad(value)
      let copyInput = { ...registro, idCiudad: value?.idCiudad };
      setRegistro(copyInput);
    }

    if (value === null) {
      setCiudad({})
      let copyInput = { ...registro, idCiudad: 0 };
      setRegistro(copyInput);
    }
  };


  const onSelectRubro = (e, value) => {
    if (value && value?.idRubro !== registro?.idRubro) {
      setRubro(value)
      let copyInput = { ...registro, idRubro: value?.idRubro };
      setRegistro(copyInput);
    }

    if (value === null) {
      setRubro({})
      let copyInput = { ...registro, idRubro: 0 };
      setRegistro(copyInput);
    }
  };

  const handleChangeDocumento = (event) => {
    let copyInput = { ...registro, documento: event.target.value };
    setRegistro(copyInput);
  };

  const handleChangeTituloSNPP = (event) => {
    let copyInput = { ...registro, tituloSnpp: event.target.value };
    setRegistro(copyInput);
  };


  const handleEliminarImagenSelfie = (event) => {
    event.preventDefault()
    setFotoSelfie({})
  };

  const handleEliminarImagenDorso = (event) => {
    event.preventDefault()
    setFotoDorsoCI({})
  };
  const handleEliminarImagenFrente = (event) => {
    event.preventDefault()
    setFotoFrenteCI({})
  };
  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        spacing={2}
      >

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Card className={classes.root}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignContent="center"
                spacing={1}
              >

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <List >
                    <ListItem >
                      <ListItemAvatar >
                        <Avatar style={{ backgroundColor: "#07a896" }}>
                          <img width="60px" alt="Logo" src={logo} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<TypographyBold variant="h5" display="block" color="primary" > Hola, Wommer</TypographyBold>}
                        secondary={<TypographyBold variant="body2" display="block" color="textSecondary" > Completa este formulario y un Asistente personal de WoM se contactará contigo para confirmar tu alta.</TypographyBold>}
                      />
                    </ListItem>
                  </List>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                  <Box pl={1} pr={1}>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ padding: 0 }}>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Box pt={3}>
                      {activeStep === steps.length ? (
                        <div>
                          <Typography className={classes.instructions}>Gracias por registrarte. Sus datos serán validados y le estaremos avisando en la brevedad posible.</Typography>

                        </div>
                      ) : (
                        <div>
                          <Typography >{getStepContent(activeStep)}</Typography>

                        </div>
                      )}
                    </Box>


                  </Box>

                </Grid>


              </Grid>


            </CardContent>

          </Card>
        </Grid>
      </Grid>
    </>
  );
}
