import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography, Chip } from "@material-ui/core";
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import logoW from '../../assets/images/logoW.png'
import { pink, lightGreen } from '@material-ui/core/colors';
import swal from 'sweetalert';
import CheckIcon from "@material-ui/icons/Check";

export default function ListaServiciosInactivo() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ content: [] });


  useEffect(() => {
    getData()
  }, []);

  const getData = async (props) => {
    setIsLoading(true);
    let url = "servicios/listar"
    try {
      const response = await axios.post(url, { estado: "INACTIVO" });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          setData({ ...data, content: responseData?.result });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const title = (<Button
    size='small'
    variant="outlined"
    color="primary"
    startIcon={<AddIcon />}
  //onClick={() => history.push("../nuevo-colaborador")}

  >
    Agregar
  </Button>);

  const columns = [
    {
      title: "ID",
      field: "idServicio",
      width: "1%",
      //  hidden: true
    },
    {
      title: "Imagen",
      field: "urlFotos",
      align: "center",

      width: "10%",
      render: (rowData) => {
        if (rowData?.urlFotos === null) {
          return (
            <img
              style={{ height: 40, borderRadius: "10%" }}
              src={`${logoW}`}
            />
          )
        } else {
          let imag = rowData?.urlFotos?.split(",")
          return (
            < img
              style={{ height: 40, borderRadius: "10%" }}
              src={`${imag ? imag[0] : rowData?.urlFotos}`}
            />
          )
        }
      }

    },
    {
      title: "Fecha Creación",
      field: "fechaCreacion",
      width: "15%",

    },
    {
      title: "Nombre del servicio",
      field: "nombre",
      //  width: "15%",
    },

    {
      title: "Rubro",
      field: "rubro",
      width: "15%",

    },
    {
      title: "Modalidad",
      field: "modalidad",
      width: "10%",
      align: "center",
      render: (rowData) => (
        rowData.modalidad === "Remota" ?
          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px" }}>
            {rowData.modalidad}
          </Alert> : <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px" }}>
            {rowData.modalidad}
          </Alert>

      ),

    },
    {
      title: "Precio",
      field: "precio",
      width: "15%",
      align: "center",
      render: (rowData) => (

        <Alert icon={false} variant="standard" severity="warning" style={{ justifyContent: "center", padding: "0px 10px" }}>
          {rowData.precio}
        </Alert>


      ),

    },


  ];
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];


  const handleDetalle = (event, props) => {
    event.stopPropagation();
    history.push("./detalle/", props.data);

  };
  const handleCambiarEstado = (event, props, estado) => {
    event.stopPropagation();
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres colocar como ${estado} a ${props.data?.nombre}?`,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        cambiarEstado(props.data, estado)
      }
    });
  };
  const cambiarEstado = async (props, estado) => {

    setIsLoading(true);
    let url = "servicios/estado"
    try {
      const response = await axios.post(url,
        {
          idServicio: props?.idServicio,
          estado: estado
        });
      let status = response.status;
      if (status === 200) {
        if (response.data?.ok) {
          getData()
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          });
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }

  };


  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circular"
              >
                <VisibilityIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleCambiarEstado(e, props, "ACTIVO")}
            avatar={
              <Avatar variant="circular"
                sx={{ bgcolor: "red" }} >
                <CheckIcon fontSize='small' style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Activar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    );
  };

  return (

    <>
      {userContext.state.nombreUsu !== "" ?
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              childrenAccions,
            }}
          />



        </>
        : <AccesoDenegado />}
    </>
  );
}
