import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

import axios from "../../utils/axios";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Avatar, Box } from "@material-ui/core";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Chip from '@material-ui/core/Chip';
import { useTheme } from "@material-ui/core/styles";
import TypographyBold from "../../components/TypographyBold";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import logo from "../../assets/images/logoW.png";
import BackdropCustom from "../../components/BackdropCustom";
import CheckIcon from '@material-ui/icons/Check';
import { alertWarningError, notificacionAlerta } from "../../components/Notificaciones";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export default function DetalleRegistro() {
  const history = useHistory();
  const dataProps = history.location.state;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [fotoFrente, setFotoFrente] = useState("");
  const [fotoDorso, setFotoDorso] = useState("");
  const [detalleWommers, setDetalleWommers] = useState({});
  const [rubroList, setRubroList] = useState([]);
  const [cursosList, setCursosList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idUsuario) {

      getData(dataProps?.idUsuario)
      getRubros(dataProps?.idUsuario)
      getCursos(dataProps?.idUsuario)



    }
  }, []);
  const getData = async (props) => {
    setIsLoading(true);
    let url = "wommer/listar"
    try {
      const response = await axios.post(url, {
        idUsuario: props,
        estado: null
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          if (responseData?.result?.length > 0) {

            setDetalleWommers(responseData?.result[0]);
            let fotos = responseData?.result[0]?.urlFotoDocumento?.split(",")
            setFotoFrente(fotos[0])
            setFotoDorso(fotos[1])
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getRubros = async (props) => {
    setIsLoading(true);
    let url = "wommer/listar-rubro";
    try {
      const response = await axios.post(url, {
        idUsuario: props,
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          setRubroList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }


      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };
  const getCursos = async (props) => {
    setIsLoading(true);
    let url = "wommer/listar-snpp";
    try {
      const response = await axios.post(url, {
        idUsuario: props,
      });
      let status = response.status;
      if (status === 200) {
        const responseData = response.data;
        if (response.data?.ok) {
          setCursosList(responseData?.result)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notificacionAlerta(response.data?.message);
        }

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" variant="rounded" alt="Remy Sharp" src={detalleWommers?.urlFotoPerfil} />
          }
          title={detalleWommers?.nombre + " " + detalleWommers?.apellido}
          subheader={
            <Chip label={"Estado: " + detalleWommers?.estado} size="small" color="secondary" />
          }
        />
        <CardContent >

          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              <div style={{
                flexGrow: 1,
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                // height: 350,
              }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  style={{
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Tab label="Datos personales" {...a11yProps(0)} />
                  <Tab label="Documentos" {...a11yProps(1)} />
                  <Tab label="Rubros" {...a11yProps(2)} />
                  <Tab label="Cursos SNPP" {...a11yProps(3)} />
                </Tabs>

                <TabPanel value={value} index={0} style={{ width: "100%" }}>
                  <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Nombre y Apellido
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.nombre + " " + detalleWommers?.apellido}
                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Fecha Nacimiento
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.fechaNacimiento ? detalleWommers?.fechaNacimiento : "Sin datos"}

                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Nro. Documento
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.nroDocumento ? detalleWommers?.nroDocumento : "Sin datos"}

                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Correo
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.correo ? detalleWommers?.correo : "Sin datos"}

                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Departamento
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.departamento ? detalleWommers?.departamento : "Sin datos"}

                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Ciudad
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.ciudad ? detalleWommers?.ciudad : "Sin datos"}

                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Barrio
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.barrio ? detalleWommers?.barrio : "Sin datos"}

                      </TypographyBold>
                    </Grid>


                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Calle principal
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.callePrincipal ? detalleWommers?.callePrincipal : "Sin datos"}

                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Calle Secundaria
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.calleSecundaria ? detalleWommers?.calleSecundaria : "Sin datos"}
                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Fecha Creación
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.fechaCreacion ? detalleWommers?.fechaCreacion : "Sin datos"}
                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Emite Factura

                      </Typography>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.emiteFactura ?
                          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }} >SI </Alert>
                          :
                          <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }} > NO </Alert>

                        }

                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Titulo SNPP

                      </Typography>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {detalleWommers?.tituloSnpp ?
                          <Alert icon={false} variant="standard" severity="success" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }} >SI </Alert>
                          :
                          <Alert icon={false} variant="standard" severity="error" style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }} > NO </Alert>

                        }

                      </Typography>
                    </Grid>
                    {detalleWommers?.motivoRechazo &&
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography
                          variant="body2"
                          display="block"
                          color="textSecondary"
                        >
                          Motivo Rechazo
                        </Typography>
                        <TypographyBold
                          variant="body2"
                          display="block"
                          color="textSecondary"
                        >
                          {detalleWommers?.motivoRechazo ? detalleWommers?.motivoRechazo : "Sin datos"}
                        </TypographyBold>
                      </Grid>

                    }


                  </Grid>
                </TabPanel>


                <TabPanel value={value} index={1} style={{ width: "100%" }}>
                  <Grid container justifyContent="center" alignItems="center" spacing={3}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Card className={classes.root}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe" >
                                  <PhotoCameraIcon />
                                </Avatar>
                              }

                              title="FOTO CI FRENTE"
                            // subheader="September 14, 2016"
                            />
                            <CardMedia
                              width="30%"
                              title="FOTO CI FRENTE"
                              height="30%"
                              component="img"
                              src={fotoFrente ? fotoFrente : logo}
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Card className={classes.root}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe" >
                                  <PhotoCameraIcon />
                                </Avatar>
                              }

                              title="FOTO CI DORSO"
                            // subheader="September 14, 2016"
                            />
                            <CardMedia
                              width="30%"
                              title="FOTO CI DORSO"
                              height="30%"
                              component="img"
                              src={fotoDorso ? fotoDorso : logo}
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Card className={classes.root}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe" >
                                  <PhotoCameraIcon />
                                </Avatar>
                              }

                              title="SELFIE"
                            // subheader="September 14, 2016"
                            />
                            <CardMedia
                              width="30%"
                              title={detalleWommers?.urlFotoSelfie}
                              height="30%"
                              component="img"
                              src={detalleWommers?.urlFotoSelfie ? detalleWommers?.urlFotoSelfie : logo}
                            />
                          </Card>
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={2} style={{ width: "100%" }}>
                  <Grid container spacing={3}>
                    {rubroList.length > 0 ? (
                      rubroList.map((value) => {
                        return (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Alert key={detalleWommers?.idRubro} icon={<CheckIcon fontSize="inherit" />} variant="standard" severity="success" style={{ padding: "0px 10px 0px 10px" }}>{value?.rubro}</Alert>
                            </Grid>
                          </>
                        );
                      })
                    ) : (
                      <Chip
                        label={"SIN RUBROS"}
                        variant="default"
                        color="secondary"
                        size="small"
                      />
                    )}
                  </Grid>

                </TabPanel>



                <TabPanel value={value} index={3} style={{ width: "100%" }}>
                  <Grid container spacing={3}>
                    {cursosList.length > 0 ? (
                      cursosList.map((value) => {
                        return (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Alert key={detalleWommers?.idSnppCurso} icon={<CheckIcon fontSize="inherit" />} variant="standard" severity="success" style={{ padding: "0px 10px 0px 10px" }}>
                                <Box pb={1}>
                                  <Chip
                                    label={value?.snppProfesion}
                                    variant="default"
                                    color="secondary"
                                    size="small"
                                  />
                                </Box>{value?.snppCurso}
                              </Alert>

                            </Grid>
                          </>
                        );
                      })
                    ) : (
                      <Chip
                        label={"SIN CURSOS REALIZADOS"}
                        variant="default"
                        color="secondary"
                        size="small"
                      />
                    )}
                  </Grid>

                </TabPanel>
              </div>


            </Grid>





          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack();
                }}
              >
                Salir
              </Button>
            </Grid>

          </Grid>
        </CardActions>
      </Card>
    </>
  );
}
