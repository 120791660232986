import React, { useState, useEffect } from "react";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import BackdropCustom from "../../components/BackdropCustom";
import { alertWarningError, notificacionEliminar } from "../../components/Notificaciones";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';


const inicialValue = {
  idMotivo: 0,
  nombre: "",
  esObservacion: false,
};

export default function EditarMotivoRechazo() {
  const history = useHistory();
  const classes = useStyles();
  const dataProps = history.location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [motivo, setMotivo] = useState(inicialValue);

  useEffect(() => {

    if (dataProps?.idMotivo === undefined) {

      swal({
        title: "¡ATENCIÓN!",
        text: `No se encontraron datos del motivo, vuelva a cargar...`,
        icon: "warning",
        buttons: true,
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
        confirmButtonColor: "#EE273E",
      }).then((willDelete) => {
        if (willDelete) {
          history.goBack();
        }
      });


    } else {
      console.log(dataProps)


      let copyInput = {
        ...motivo,
        idMotivo: dataProps?.idMotivo,
        nombre: dataProps?.nombre,
        esObservacion: dataProps?.esObservacion ? true : false,
      };
      setMotivo(copyInput)

    }

  }, []);

  const handleChangeNombre = (event) => {
    let copyInput = { ...motivo, nombre: event.target.value };
    setMotivo(copyInput);
  };

  const handleChangeEsObservacion = (event) => {
    let copyInput = { ...motivo, esObservacion: event.target.checked };
    setMotivo(copyInput);
  };

  const handleGuardar = async () => {

    setIsLoading(true);
    let url = "rechazowommer/actualizar";
    try {
      const response = await axios.post(url, motivo);
      let status = response.status;
      if (status === 200) {

        setIsLoading(false);
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        });
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={6} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="nombre"
                name="nombre"
                label="Nombre del motivo"
                type="text"
                value={motivo.nombre}
                onChange={(value) => handleChangeNombre(value)}
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item sm={12} md={2} lg={2} xl={2}>
              <FormGroup row>
                <FormControlLabel
                  className={classes.DialogoTexto}
                  control={
                    <Checkbox
                      checked={motivo.esObservacion}
                      onChange={(value) => handleChangeEsObservacion(value)}
                      name="Observación"
                      color="primary"
                    />
                  }
                  label="Observación"
                />


              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack();
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={motivo.nombre === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar();
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
}
